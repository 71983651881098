.button {
  font-size: 14px;
  height: 35px;
  border-radius: 27px;
  font-weight: 700;
  width: 100px;
  position: relative;
  border: 1px solid white;
}

.button:focus {
  outline: none;
}

.button_submit {
  background: white;
  color: black;
}

.button_back {
  background: transparent;
  color: white;
}

.button_back:only-child {
  margin: 0 auto;
}