.Footer {
  color: rgb(179, 179, 179);
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
}

.FooterButton {
  border: none;
  background: none;
  outline: none;
  font-size: 12px;
  color: rgb(179, 179, 179);
  margin-top: 15px;
  cursor: pointer;
}

.ContactUs {
  font-weight: bold;
  text-decoration: underline;
}
