.main {
  position: relative;
}

.for_mobile,
.is_mobile {
  display: none;
}

.banner {
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
}

.first_banner {
  /*background-image: url("../../../assets/images/header_bg_2.png");*/
  height: 110vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.header_link {
  display: flex;
  justify-content: center;
}

.header_link_styles {
  position: relative;
  padding: 5px 20px;
  width: 140px;
  height: 35px;
  border: 1.2px solid white;
  border-radius: 27px;
  color: white;
  text-decoration: none;
  margin: 34px 10px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}
button:focus {
  outline: none;
}

.header_link_child_1 {
  background: white;
  color: black;
}

.header_logo_wapper {
  position: absolute;
  /* bottom: 100px; */
  left: 50%;
    transform: translate(-50%);
    height: 50vh;
    align-items: center !important;
    /* justify-content: center !important; */
    display: flex !important
}

.header_logo {
  margin-top: 503px;
}

.header_logo img {
  max-width: 590px;
}

.header_cylinder {
  height: 203px;
  display: flex;
  justify-content: center;
}

.second_banner {
  background-image: url("../../../assets/images/i_stock_2.png");
  max-height: 863px;
  padding-bottom: 127px;
  width: 100%;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  margin-top: -60px;
}

.second_banner_bg1 {
  opacity: 0.67;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content_shoot_to_kill {
  display: flex;
  justify-content: center;
  color: white;
}

.content_shoot_to_kill_div {
  margin-top: 100px;
  text-align: center;
}

.content_shoot_to_kill_h1 {
  font-size: 70px;
  font-family: "Didot";
}

.content_shoot_to_kill_p {
  font-size: 30px;
  text-align: center;
  font-family: "Didot";
  margin-top: 10px;
}

.shoot_to_kills {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1060px;
  width: 100%;
  margin-top: 95px;
  margin-bottom: 162px;
}

.shoot_to_kills_div {
  background: black;
  color: #ffffff;
  padding-bottom: 10px;
  width: 290px;
  height: max-content;
}

.shoot_to_kills img {
  width: 290px;
}

.shoot_to_kills div:nth-child(2) > img {
  margin-top: -16px;
}

.shoot_to_kills_div p {
  text-align: center;
  font-family: Didot;
  font-size: 21px;
}

.shoot_to_kills div:nth-child(1) p {
  padding: 13px 0px 20px 0px;
}

.shoot_to_kills div:nth-child(2) p {
  padding: 8px 0px 20px 0px;
}

.shoot_to_kills div:nth-child(3) p {
  padding: 4px 35px 20px 35px;
}

.third_banner {
  display: flex;
  justify-content: center;
}

.third_banner_img {
  max-height: 642px;
  width: auto;
  border-radius: 0 0 10px 10px;
}

.fourth_banner {
  display: flex;
  justify-content: center;
  padding-top: 5%;
}

.fourth_banner_wrap_content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
}

.fourth_banner_wrap_content_to_content {
  color: white;
  font-size: 17px;
}

.fourth_banner_wrap_content_to_content > div {
  margin: 30px 0;
}

.fourth_banner_wrap_content_to_content > div:last-child {
  margin-bottom: 0;
}

.fourth_banner_wrap_content_to_content_div_p_child_1 {
  margin: 5px 0;
  font-weight: 700;
}

.fourth_banner_wrap_content_to_content_div_p_child_2 {
  font-weight: 300;
}

.fourth_banner_wrap_content_to_content > p {
  font-family: "Didot-Regular";
}

.fourth_banner_wrap_content_to_content_title {
  font-size: 70px;
  font-family: "Didot-Regular";
}

.fourth_banner_wrap_images {
  width: 65%;
  margin-bottom: -3%;
}

.fourth_banner_wrap_images_first_block {
  display: flex;
  position: relative;
  top: 13px;
  left: 138px;
  width: 90%;
}

.fourth_banner_wrap_images_first_block_block_1 {
  width: 40.2%;
  z-index: 2;
}

.for_desktop {
  z-index: 3;
}

.fourth_banner_wrap_images_first_block_block_1_div {
  background: #414449;
  text-align: left;
  color: white;
  padding: 5% 12%;
  font-size: 15px;
  font-weight: 300;
}

.fourth_banner_wrap_images_first_block_block_2_img {
  margin-left: -20%;
  margin-top: 7%;
}

.fourth_banner_wrap_images_first_block_block_2_p {
  font-size: 26px;
  color: white;
  margin: 10px 40px;
  text-align: left;
  font-family: "Didot";
}
.we_strongly_mobile {
  display: none;
}
.fourth_banner_wrap_images_second_block {
  display: flex;
}
.fourth_banner_wrap_images_second_block img {
  z-index: 1;
  height: 370px;
  width: auto;
}

.fourth_banner_wrap_images_second_block_content_left {
  margin-left: -38%;
  z-index: 2;
  font-size: 25px;
  width: 38%;
}

.fourth_banner_wrap_images_second_block_content_left_div_child_1 {
  margin: 38px 0 38px;
  font-size: 25px;
  font-family: "Didot";
}

.fourth_banner_wrap_images_second_block_content_left_div_child_2 {
  background: #414449;
  margin-right: -20px;
  color: white;
  padding: 40px 26% 40px 13%;
  font-size: 15px;
  font-weight: 300;
}
.fourth_banner_wrap_images_second_block_content_left_div_child_2_mobil {
  display: none;
}

@media all and (max-width: 1450px) {
  .second_banner {
    background-size: 100% 121%;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
  .second_banner {
    background-position: 70%;
    padding-bottom: 150px;
    background-size: cover;
  }
  .second_banner .shoot_to_kills {
    max-width: 93%;
  }
  .third_banner img {
    margin-top: -13%;
    z-index: 1;
    margin-bottom: 10%;
  }
  .fourth_banner {
    padding-top: 0;
    margin-left: 20px;
  }
  .fourth_banner .fourth_banner_wrap_content_to_content > div {
    margin: 20px 0;
  }
  .fourth_banner .fourth_banner_wrap_content_to_content_div_p_child_1 {
    margin-bottom: 10px;
  }
  .fourth_banner .fourth_banner_wrap_images {
    width: 60%;
    top: 10px;
  }
  .fourth_banner .fourth_banner_wrap_images_first_block {
    top: 10px;
    left: 20px;
    width: 95%;
  }
  .fourth_banner .fourth_banner_wrap_images_first_block_block_1 {
    width: 100%;
  }
  .fourth_banner .fourth_banner_wrap_images_first_block_block_1_div {
    padding: 15px 21px 8px 21px;
  }
  .fourth_banner .for_desktop {
    width: 100%;
    height: 30.7%;
  }
  .fourth_banner .fourth_banner_wrap_images_first_block_block_2_img {
    margin-top: 10%;
    margin-left: -56px;
  }
  .fourth_banner .fourth_banner_wrap_images_first_block_block_2_p {
    margin: 5% 10%;
  }
  .fourth_banner .fourth_banner_wrap_images_second_block_content_left {
    margin-left: -44%;
    width: 100%;
  }
  .fourth_banner
    .fourth_banner_wrap_images_second_block_content_left_div_child_2 {
    position: relative;
    padding: 30px 27px 20px 27px;
    bottom: -15px;
    margin-right: auto;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .header_logo img {
    max-width: 442px;
  }

  .header_cylinder {
    height: 160px;
  }

  .first_banner .is_mobile {
    display: none;
  }

  .first_banner {
    background-size: cover;
    background-position: top;
    height: 110vh;
  }

  .first_banner .header_link .header_link_styles {
    margin-top: 34px;
    z-index: 1;
  }

  .second_banner {
    background-position: 100%;
    padding-bottom: 80px;
    background-size: cover;
  }

  .second_banner .shoot_to_kills {
    max-width: 89%;
    margin-top: 31px;
    margin-bottom: 32px;
  }

  .second_banner .content_shoot_to_kill_div {
    margin-top: 70px;
  }

  .second_banner .shoot_to_kills_div {
    max-width: 187px;
    max-height: 163px;
  }

  .second_banner .shoot_to_kills_div p {
    font-size: 10px;
  }

  .second_banner .shoot_to_kills div:nth-child(2) > img {
    margin-top: -10px;
  }

  .second_banner .content_shoot_to_kill_h1 {
    font-size: 45px;
  }

  .second_banner .content_shoot_to_kill_p {
    font-size: 20px;
  }

  .second_banner .shoot_to_kills div:nth-child(1) p {
    padding: 13px 20px 20px 20px;
  }

  .second_banner .shoot_to_kills div:nth-child(2) p {
    padding: 9px 20px 20px 20px;
  }

  .second_banner .shoot_to_kills div:nth-child(3) p {
    padding: 6px 20px 20px 20px;
  }

  .third_banner .third_banner_img {
    max-height: 481px;
    max-width: 432px;
    z-index: 1;
    margin-bottom: 87px;
    margin-top: -86px;
  }

  .fourth_banner {
    padding-top: 0;
    margin-left: 20px;
  }

  .fourth_banner
    .fourth_banner_wrap_content_to_content
    .fourth_banner_wrap_content_to_content_title {
    font-size: 52px;
  }

  .fourth_banner .fourth_banner_wrap_content_to_content > p {
    font-size: 22px;
  }

  .fourth_banner .fourth_banner_wrap_content_to_content > div {
    margin: 20px 0;
  }

  .fourth_banner .fourth_banner_wrap_content_to_content_div_p_child_1 {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .fourth_banner .fourth_banner_wrap_content_to_content_div_p_child_2 {
    font-size: 12px;
  }

  .fourth_banner .fourth_banner_wrap_images {
    width: 60%;
    top: 10px;
  }

  .fourth_banner .fourth_banner_wrap_images_first_block {
    top: 10px;
    left: 20px;
    width: 95%;
  }

  .fourth_banner .fourth_banner_wrap_images_first_block_block_1 {
    width: 98%;
  }

  .fourth_banner .fourth_banner_wrap_images_first_block_block_1_div {
    padding: 15px 21px 8px 21px;
    font-size: 11.25px;
  }

  .fourth_banner .for_desktop {
    width: 100%;
    height: 35.7%;
    max-height: none;
  }

  .fourth_banner .fourth_banner_wrap_images_first_block_block_2_img {
    margin-top: 10%;
    margin-left: -56px;
    max-width: 271px;
  }

  .fourth_banner .fourth_banner_wrap_images_first_block_block_2_p {
    font-size: 19.5px;
    margin: 0;
    margin-left: 22.5px;
  }

  .fourth_banner .fourth_banner_wrap_images_second_block_content_left {
    margin-left: -44%;
    width: 100%;
  }

  .fourth_banner
    .fourth_banner_wrap_images_second_block_content_left_div_child_1 {
    font-size: 18.75px;
    margin: 40px 0 10px 0;
    font-weight: 700;
  }

  .fourth_banner
    .fourth_banner_wrap_images_second_block_content_left_div_child_2 {
    position: relative;
    font-size: 11.25px;
    padding: 30px 27px 20px 27px;
    bottom: -15px;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .main {
    width: 100%;
  }
  .banner {
    background-size: 140%;
    background-position: top;
    position: relative;
  }
  .header_cylinder_div {
    margin-top: 0;
  }
  .header_cylinder {
    height: 0px;
    display: block;
    position: absolute;
    top: 79%;
    width: 100%;
  }
  .header_cylinder img {
  }
  .header_link {
    right: 0;
    left: 0;
    z-index: 1;
  }
  .header_link_styles {
    width: 130px;
    font-size: 12px;
    margin: 20px 10px;
    padding: 7px 25px;
  }
  .first_banner {
    height: 110vh;
    background-position: top center;
    background-size: 100%;
  }
  .first_banner .is_mobile {
    display: block;
  }
  .first_banner .is_desktop {
    display: none;
  }
  .first_banner .header_logo {
    margin-top: auto;
    display: flex;
    justify-content: center;
  }
  .header_logo img {
    width: 370px;
    height: 117px;
    max-width: none;
  }
  .fourth_banner_wrap_images_second_block_content_left_div_child_2 {
    margin-right: 0;
  }
  .fourth_banner_wrap_images_second_block_content_left_div_child_2_desktop {
    display: none;
  }
  .fourth_banner_wrap_images_second_block_content_left_div_child_2_mobil {
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding-right: 20px !important;
    text-align: center;
  }
  .fourth_banner_wrap_images_second_block_content_left_div_child_2_mobil
    .we_believe_that_talent_mobile {
    /* padding: 0 50px; */
    /* text-align: center; */
  }
  .content_shoot_to_kill_h1 {
    font-size: 50px;
    height: 108px;
  }
  .content_shoot_to_kill_p {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
  }
  .shoot_to_kills_div {
    font-size: 13px;
  }
  .shoot_to_kills {
    padding: 0 10%;
    width: 100%;
    margin: auto;
    margin-top: 18%;
    display: block;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .shoot_to_kills img {
    width: 100%;
  }
  .second_banner {
    display: inline-block;
    height: 100%;
    background-size: 931px 518px;
    margin-top: 0;
    position: initial;
  }
  .third_banner {
    background-image: url("../../../assets/images/blondeleft.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    max-width: 100%;
  }
  .third_banner img {
    margin-top: 33px;
    margin-bottom: -93px;
  }
  .fourth_banner {
    display: initial;
  }
  .fourth_banner .fourth_banner_wrap_content {
    display: block;
  }
  .fourth_banner .fourth_banner_wrap_content p {
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    font-family: "Didot";
  }
  .fourth_banner .fourth_banner_wrap_content_to_content > p {
    line-height: 38px;
    font-size: 30px;
    font-weight: 400;
  }

  .fourth_banner_wrap_content_to_content {
    width: 100%;
    padding: 0 20px 0 20px;
    font-size: 30px;
    margin-top: 212px;
  }
  .fourth_banner_wrap_content_to_content_title {
    font-size: 40px;
  }
  .fourth_banner_wrap_content_to_content_div_p_child_1 {
    font-weight: 700 !important;
  }
  .fourth_banner_wrap_content_to_content_div_p_child_1,
  .fourth_banner_wrap_content_to_content_div_p_child_2 {
    font-size: 15px !important;
    font-family: "Open Sans" !important;
  }
  .fourth_banner_wrap_content_to_content_div_p_child_2 {
    font-weight: 300 !important;
    line-height: 20px !important;
  }
  .fourth_banner_wrap_images_first_block {
    width: 100%;
    top: 34px;
    left: 0;
    z-index: 2;
    position: initial;
    margin-top: 20px;
  }
  .fourth_banner_wrap_images {
    width: 100%;
  }
  .fourth_banner_wrap_images_first_block {
    display: block;
  }
  .fourth_banner_wrap_images_first_block_block_1 {
    width: 100%;
    height: 100%;
  }
  .fourth_banner_wrap_images_first_block_block_1 img {
    width: 100%;
  }
  .fourth_banner_wrap_images_first_block_block_1_div {
    padding: 50px 5% 20px 20px;
    position: relative;
    text-align: center;
  }
  .we_strongly_desktop {
    display: none;
  }
  .we_strongly_mobile {
    display: block;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
    margin-top: 15px;
  }
  .we_strongly_mobile_one {
  }
  .for_desktop {
    display: none;
  }
  .for_mobile {
    display: block;
  }
  .fourth_banner_wrap_images_first_block_block_1_img {
    width: 240px;
    height: 83px;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
  .fourth_banner_wrap_images_first_block_block_1_img img {
    width: 95%;
  }
  .fourth_banner_wrap_images_first_block_block_2_p {
    font-size: 20px;
    height: 62px;
    margin: 33px 20px 25px 20px;
    line-height: 28px;
  }
  .fair_access_mobile,
  .available_mobile {
    text-align: center;
    /* font-size: 22px; */
  }
  .available_mobile {
  }
  .fourth_banner_wrap_images_second_block {
    display: block;
    position: relative;
  }
  .fourth_banner_wrap_images_second_block_bg {
    background-image: url("../../../assets/images/girlsinleather.png");
    width: 100%;
    background-size: 120%;
    background-position: right;
    height: 202px;
    background-repeat: no-repeat;
  }
  .fourth_banner_wrap_images_second_block_content_left {
    margin-left: 0;
    width: 100%;
  }
  .fourth_banner_wrap_images_second_block_content_left_div_child_2 {
    padding: 20px 32% 20px 20px;
  }

  .fourth_banner_wrap_images_second_block_content_left_div_child_1 {
    position: absolute;
    font-size: 15px;
    width: 141px;
    top: 0;
    right: 20px;
    display: flex;
    align-items: center;
    bottom: calc(100% - 200px);
  }
  .header_cylinder {
    top: 55%;
  }
}
@media only screen and (max-width: 400px) {
  .header_cylinder {
    top: 60%;
  }
  .header_logo img {
    width: 290px;
    height: 92px;
    margin-bottom: 34px;
  }
  button[data-for="registerTip"] {
    display: none;
  }
}

@media only screen and (min-width: 415px) and (max-width: 767px) {
  .third_banner {
    background-position: top center;
    background-size: contain;
    margin-top: 305px;
    background-image: none;
  }
  .third_banner img {
    margin-bottom: 0;
    width: 100%;
    padding-left: 17px;
    padding-right: 17px;
  }
  .fourth_banner_wrap_content_to_content {
    margin-top: 112px;
  }
  .fourth_banner_wrap_images_second_block_bg {
    height: 302px;
    margin-bottom: -5%;
  }
  .fourth_banner_wrap_images_second_block_content_left_div_child_1 {
    bottom: calc(100% - 73%);
  }
}
@media only screen and (min-width: 650px) and (max-width: 767px) {
}

@media only screen and (min-width: 490px) and (max-width: 767px) {
  .second_banner .shoot_to_kills {
    width: 70%;
  }
}
.third_banner_img_mobile {
  display: none;
}
@media only screen and (min-width: 415px) and (max-width: 490px) {
  .second_banner .shoot_to_kills {
    width: 90%;
  }

  .third_banner_img {
    display: none;
  }
  .fourth_banner_wrap_content_to_content {
    padding-bottom: 14px;
  }
  .third_banner_img_mobile {
    display: block;
    width: 100%;
    padding: 0px 14px 0px 12px;
  }
  .header_cylinder {
    bottom: -160px;
  }
}

@media only screen and (min-width: 416px) {
  .fourth_banner .fourth_banner_wrap_images_second_block_bg {
    height: 218px;
    background-size: 100%;
    background-position: center;
  }
}

@media only screen and (max-width: 416px) {
  .third_banner {
    margin-top: 250px;
  }
  .third_banner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 376px) and (max-width: 414px) {
  .third_banner {
    background-image: none;
  }
  .third_banner_img {
    display: none;
  }

  .third_banner img {
    margin-top: 33px;
  }
  .third_banner_img_mobile {
    display: block;
    width: 100%;
    padding: 0px 14px 0px 12px;
  }
  .fourth_banner_wrap_content_to_content {
    padding-bottom: 14px;
  }
  .header_cylinder {
    bottom: -130px;
  }
}
@media only screen and (max-width: 375px) {
  .third_banner {
    background-image: none;
  }
  .first_banner {
    height: 95vh;
  }
  .third_banner_img {
    display: none;
  }

  .third_banner img {
    margin-top: 45px;
  }
  .third_banner_img_mobile {
    display: block;
    width: 100%;
  }
  .fourth_banner_wrap_content_to_content {
    padding-bottom: 14px;
  }
  .header_cylinder {
    bottom: -100px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) { 
  .first_banner {
    background-size: contain;
    background-position: top;
    height: 110vh;
  }
}

@media only screen and (max-width: 787px) {
  .first_banner {
    background-position: top;
    background-size: 100%;
    height: 70vh !important;
  }
}

@media only screen and (max-width: 767px) {
  .header_logo img {
    width: 370px;
    height: 100% !important;
    max-width: none;
  }
}

@media only screen and (max-width: 400px) {
  .header_logo img {
    width: 290px;
    height: 100% !important;
    margin-bottom: 34px;
  }
}

@media only screen and (max-width: 600px) {
  .header_logo img {
    width: 400px;
    height: 100% !important;
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 787px) {
  .first_banner {
    background-position: top;
    background-size: 100%;
    height: 70vh !important;
    margin-bottom: 200px;
  }
}