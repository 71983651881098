@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700');
@import url('https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css');
@font-face {
  font-family: 'Didot-Regular';
  src: url('../../assets/fonts/didot/GFSDidot-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  background: black;
}

h1 {
  line-height: 1.28em;
  font-size: 32px;
  font-weight: 500;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('./../../assets/fonts/opensans_light/OpenSans-Light.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 300;
}
