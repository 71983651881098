.wrap_checkbox {
  cursor: pointer;
  font-size: 22px;
  padding-left: 50px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.17);
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 15px 15px 15px 51px;
  height: 45px;
}

label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 16px;
  opacity: 0;
}

.wrap_checkbox .check_mark {
  position: absolute;
  transform: translate(0%, -50%);
  left: 15px;
  top: 50%;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  border-radius: 4px;
}

.wrap_checkbox .label {
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #fff;
}

.checked {
  background: white;
}

.checked .check_mark {
  background-color: white;
  border: 1px solid black;
}

.checked .label {
  color: #000;
}

.checked .check_mark::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
