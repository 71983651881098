.Input {
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px 20px;
  font-size: 15px;
  color: #939598;
  font-weight: 100;
  outline: none;
  width: 290px;
  font-size: 16px;
}

.InputError {
  border: 1px solid #e04344;
}

.ErrorInfo {
  color: #e04344;
}

.menu {
  z-index: 100;
  background: white;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border-radius: 18px;
}

.item {
  margin: 10px;
  border-radius: 18px;
  display: block;
  color: black;
  padding: 2px 6px;
  cursor: pointer;
}

.itemHighlighted {
  color: black;
}

.AdminInput {
  user-select: none;
  border: 1px solid white;
  background: none;
  color: white;
  font-size: 19px;
  padding: 10px 15px;
  outline: none;

  position: relative;
  width: 100%;
  flex-shrink: 0;
  cursor: pointer;
}

.AdminInput::placeholder {
  color: white;
  opacity: 1;
}
