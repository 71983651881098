.step_second,
.step_third,
.step_fourth,
.step_five,
.step_confirmation,
.success {
  color: white;
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  height: auto;
}

.step_five_action_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 33px 10px;
}

@media screen and (max-width: 1024px) {
  .step_second,
  .step_third,
  .step_fourth {
    background-size: cover;
  }
}

.step_second_wrapper {
  width: 380px;
  padding-top: 70px;
}

.title {
  font-size: 30px;
}

.title_stepfive {
  font-size: 21.5px;
}

.title_confirmation {
  font-size: 24px;
}

.sub_title {
  font-size: 14px;
}

.sub_title_step_third {
  font-size: 19px;
  padding: 12px 0;
}

.sub_title_five {
  font-size: 16px;
}

.sub_title_confirmation {
  font-size: 16px;
}

.logo {
  width: 346px;
  margin: auto;
  margin-bottom: 32px;
}

form {
  margin: 24px auto 33px;
  width: 354px;
}
.step_confirmation form {
  width: 320px;
}

form .instagram_input {
  position: relative;
  height: 48px;
  margin-bottom: 25px;
}

form .instagram_input span {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
}

.red_star_self {
  margin-left: 88px !important;
}
.red_star_referrer {
  display: none;
}

.tool_tip {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

form .instagram_input label {
  position: absolute !important;
  margin-left: 55px !important;
}

form .instagram_input .input_prefix {
  position: absolute !important;
  left: 40px !important;
}

.footer {
  font-size: 11px;
  line-height: 19px;
  margin-top: 33px;
  padding-bottom: 60px;
}

.footer a {
  text-decoration: underline;
  font-weight: 700;
}

.step_second form > div {
  margin-bottom: 15px;
}

.step_third form .wrap_places_auto {
  position: relative;
}

.step_third form .places_auto_input {
  padding: 12px 20px;
  border: 1px solid rgba(255, 255, 255, 0.17);
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  width: 100%;
  background: #1a1a1a;
  margin: 12.5px 0;
  color: white;
  font-weight: 400;
}

.step_third form .places_auto_input::placeholder {
  color: white;
}

.step_third form .menu {
  border-radius: 10px;
  top: 62px;
  z-index: 2;
  text-align: left;
}

.step_third form > div:nth-child(n + 1) {
  cursor: pointer;
}

.step_third form > div:last-child {
  margin-top: 65px;
}

.step_third .sub_title {
  margin: 10px 0 15px 0;
}

.step_fourth {
  background-position-x: 0;
  height: 100vh;
}

.step_fourth .wrap_instagram,
.step_confirmation .wrap_instagram {
  display: flex;
  background: white;
  align-items: center;
  color: black;
  padding: 5px 13px;
  border-radius: 27px;
  font-family: "Open Sans";
  font-weight: bold;
  margin: 52px auto 0 auto;
  cursor: pointer;
  font-size: 14px;
}

.wrap_connect_instagram {
  max-width: 223px;
  width: 100%;
}

.wrap_confirm_instagram {
  max-width: 250px;
  width: 100%;
}

.step_fourth .wrap_instagram div {
  text-align: left;
}

.step_fourth .wrap_instagram div,
.step_confirmation .wrap_instagram div {
  margin: auto 9px;
}

.step_fourth .content {
  margin: 33px;
}

.step_five .list_image {
  max-height: 358px;
  width: 100%;
  background: black;
  margin: 33px 0;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 1.5%;
}

.step_five .notEnoughImages {
  padding: 1.5%;
}

.step_five .list_image_active_next_page {
  position: relative;
}

.step_five .list_image_active_next_page::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.step_five .list_image_active_next_page::before {
  content: "";
  width: 50px;
  height: 50px;
  border: 4px solid #d3d3d3;
  border-top-color: #778899;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.step_five .list_image::-webkit-scrollbar {
  width: 6px;
}

.step_five .list_image::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 3px;
}

.step_five .list_image .image {
  height: 30%;
  width: 30%;
  margin: 1.5%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.step_five .list_image .image img {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.step_five .list_image .image .selected_image {
  position: absolute;
  width: 50px;
  margin: auto;
  left: 0;
  right: 0;
  margin: auto;
}

.step_five .button_submit[disabled] {
  background: #1d1d1f;
  border: none;
  pointer-events: none;
}

.step_fourth,
.step_confirmation,
.success {
  background-size: cover !important;
}

.success {
  height: 100vh;
}

.step_confirmation > div {
  width: 320px !important;
}

.step_confirmation .sub_title {
  margin: 33px 0;
}

.step_confirmation .resend_verify {
  border: 1px solid white;
  padding: 8px 14px;
  border-radius: 27px;
  cursor: pointer;
  margin: 0 10%;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  max-width: 210px;
  width: 100%;
  color: white;
}

.step_confirmation .resend_verify_disable {
  border: 1px solid gray;
  padding: 8px 14px;
  border-radius: 27px;
  cursor: pointer;
  margin: 0 10%;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  max-width: 210px;
  width: 100%;
  color: gray;
}

.sms_verify_disable div input,
.email_verify_disable div input {
  border-radius: 10px !important;
  height: 45px !important;
  width: 42px !important;
  background: #1a1a1a !important;
  border: 1px solid rgba(255, 255, 255, 0.17) !important;
  outline: none !important;
}

.sms_verify,
.email_verify {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.no_margin {
  margin: 0px !important;
}

.step_confirmation .wrap_sms_verify,
.wrap_email_verify {
  margin: 30px 0px 38px;
  position: relative;
}

.step_confirmation .wrap_email_verify span,
.step_confirmation .wrap_sms_verify span {
  transform: translate(-290%, 0%);
}

.step_confirmation .follow_instagram {
  display: flex;
  justify-content: space-between;
}

.step_confirmation .wrap_sms_verify .sms_verify input,
.step_confirmation .wrap_email_verify .email_verify input {
  width: 43px !important;
  height: 46px !important;
  margin-bottom: 9px !important;
  padding: 12px 10px;
  text-align: center;
}

.step_confirmation .sms_checked,
.step_confirmation .follow_checked {
  background: none;
  position: absolute;
  right: -2%;
  top: 33%;
}

.display_none {
  display: none !important;
}

.wrap_sms_verify .checked_confirmation,
.wrap_email_verify .checked_confirmation,
.follow_instagram .follow_checked {
  border: none;
}

.step_confirmation .follow_checked {
  top: 23%;
}

.step_confirmation .sms_checked input,
.step_confirmation .follow_checked input {
  width: 100% !important;
  top: 18px;
  left: 8px;
}

.step_confirmation .email_verify input:last-child {
  margin-bottom: auto;
}

.step_confirmation .wrap_instagram {
  margin-bottom: 0;
}

.step_confirmation .follow_instagram {
  position: relative;
  justify-content: center;
}

.success .back_home {
  padding: 8px 14px;
  width: auto;
}

.confirm_block_container {
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
  width: 340px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
  margin-bottom: 25px;
}

.container {
  padding-left: 50px;
  cursor: pointer;
  font-size: 22px;
  width: 0px;
}

.input {
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 20px;
  color: #ffffff;
  width: 340px;
  outline: none;
  margin-bottom: 25px;
}

.input_prefix {
  margin-left: 10px;
}

.input_group_in_input {
  border-radius: 10px !important;
  padding-left: 66px;
  border-radius: unset;
  font-size: 14px;
}

.input_icons {
  font-size: 14px;
}
.input::placeholder,
.combobox_phone_number input::placeholder {
  color: #ffffff;
}
.input:last-child {
  margin-bottom: 0;
}

.input_group {
  display: flex;
  align-items: center;
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 20px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 25px;
}

.input_group::placeholder {
  color: #ffffff;
}

.input_group:focus {
  outline: none;
}

input:last-child {
  margin-bottom: 0;
}

.description_tooltip {
  background: #000000;
  border: 1px solid #979797;
  width: max-content;
  max-width: 300px;
  max-height: 260px;
  border-radius: 5px !important;
}
.description_tooltip p {
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}
.description_tooltip::before {
  border-top: 8px solid #979797;
}

.wrap_phone_number {
  margin-bottom: 25px;
  background: #1a1a1a;
  border-radius: 10px;
  position: relative;
}

.wrap_phone_number label {
  position: absolute;
  right: 20px;
  top: 12px;
}

.confirm_block_container {
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
  width: 100%;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
  margin-bottom: 25px;
}

.wrap_checkbox {
  padding-left: 50px;
  cursor: pointer;
  font-size: 22px;
  width: 0px;
}

.wrap_checkbox_input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check_mark {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: 20px;
  height: 24px;
  width: 24px;
  border: 1px solid #979797;
  border-radius: 15px;
}

input:checked ~ .check_mark::after {
  display: block;
}

.check_mark::after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.text {
  font-size: 12px;
  line-height: 16px;
  align-items: center;
}

.text_span {
  font-weight: 700;
  text-decoration: underline;
}

.button {
  background: #ffffff;
  border-radius: 27px;
  color: #000000;
  width: 100px;
  height: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  border: none;
  font-weight: 700;
}

.button:focus {
  outline: none;
}

.form_require {
  width: 340px;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form_require input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form_require input:-webkit-autofill,
.form_require input:-webkit-autofill:hover,
.form_require input:-webkit-autofill:focus {
  -webkit-text-fill-color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.166603) !important;
  box-shadow: 0 0 0px 1000px #1a1a1a inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset !important;
  background-clip: content-box;
}

.form_require input[type="submit"] {
  width: auto;
}

.form_require .placeholder_label {
  pointer-events: none;
  margin: 12px 11px;
  padding: 0;
  white-space: nowrap;
  color: #999;
  left: 10px;
  color: #ffffff;
  position: absolute;
}
.instagramHandlePlaceholder,
.instagramHandleInput,
.referrerInput,
.referrerPlaceholder {
  font-size: 14px;
}

.instagramHandlePlaceholder,
.referrerPlaceholder {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.form_require .placeholder_label span {
  color: #e4293a;
}

.combobox_phone_number > div {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
}

.combobox_phone_number > div > div {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 20px;
}

.combobox_phone_number select {
  background-color: transparent;
  border: none;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  outline: none;
  position: absolute;
  width: 100%;
  opacity: 0;
}

.combobox_phone_number option {
  color: #000000;
}

.combobox_phone_number input {
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  width: 100%;
  background: #1a1a1a;
  width: 100%;
}

.combobox_phone_number > div > div > div:last-child {
  display: block;
  content: "";
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 10px;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;

  opacity: 0.7;
  font-size: 16px;
  transition: color 0.1s;
}

.disabled_btn {
  background: transparent !important;
  color: white !important;
  border: 1px solid white !important;
  opacity: 0.5;
}

.wrapper_button {
  margin-top: 47px;
}

.h1 {
  text-align: center;
  color: white;
  font-size: 35px;
  font-weight: 900;
  margin: 10px 0;
}

.h2 {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.p {
  text-align: center;
  margin: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: white;
}

.p .b {
  font-weight: 900;
  color: #000;
}

.check {
  width: 75px;
  margin: 10px;
}

.ModalBackground {
  height: auto !important;
  background-color: rgb(147, 150, 152) !important;
  border-radius: 10px;
  width: auto !important;
}

.ModalContent {
  padding: 0px !important;
  text-align: center;
}

.ModalSelectButton {
  min-width: 200px !important;
  margin: auto !important;
}

.CodeInput {
  margin-top: 17px;
}

.CodeInput input[type="number"]::-webkit-outer-spin-button,
.CodeInput input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.danger_color input[type="number"] {
  border-color: #e4293a !important;
}

.CodeInput input[type="number"] {
  -moz-appearance: textfield;
  background: #1A1A1A;
  border: 1px solid rgba(255, 255, 255, 0.17);
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.CheckContainer {
  padding: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;

  display: flex;
  align-items: center;
}

.Check {
  height: 20px;
}

.ErrorInfo {
  color: #e04344;
}

.InputError {
  border: 1px solid #e04344;
}

.step_confirmation .in_valid_code span {
  border-color: #e4293a;
}

.location_rows {
  margin-bottom: 15px;
}

.location_rows > div:first-child {
  margin-right: 10.5px;
}

.location_rows > div:last-child {
  margin-left: 10.5px;
}

.error_input {
  border: 1px solid #e4293a;
}

.error_input_phone > div {
  border: 1px solid #e4293a;
}

.error_form {
  color: white;
  font-size: 13px;
  font-weight: bold;
  left: 0;
  right: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  text-transform: uppercase;
}

.error_form_stepconfirmation {
  height: 36px;
  width: 221px;
  margin-top: 9px;
}

.error_form_stepconfirmation .warning_icon {
  margin-right: 21px;
  transform: translate(0%, 0%) !important;
}
.error_form_stepconfirmation .warning_msg {
  height: 36px;
  width: 176px;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.warning_icon {
  margin-right: 16px;
}

.require_phone_number {
  color: #e4293a;
  width: 0;
  height: 0;
}

@media screen and (max-width: 767px) {
  .step_first .confirm_block_container {
    padding: 12px 16px;
  }
  .step_first .wrap_checkbox {
    padding-left: 38px;
  }
  .step_second {
    background-image: url("../../assets/images/afro.png") !important;
  }
}

@media screen and (max-width: 445px) {
  .form_require input[required]:invalid + label {
    font-size: 12px;
    margin: 15px 11px;
  }
  .step_first .wrap_phone_number {
    font-size: 12px;
  }
}

.wrap_autocomplete {
  position: relative;
}

.wrap_autocomplete label {
  position: absolute;
  top: 25px;
  color: #e4293a;
  width: 0;
  height: 0;
  right: calc(100% - 218px);
}

.step_five .image_count {
  font-size: 15px;
}

.step_first .error_form {
  position: relative;
  margin-bottom: 15px;
  display: flex;
}

.step_five .title {
  margin-bottom: 12px;
}

.error_message {
  color: #e4293a;
}

.success .footer {
  margin-top: 297px;
}
@media screen and (max-width: 500px) {
}

@media screen and (max-width: 440px) {
  .step_second_wrapper {
    padding: 35px 0px 0px;
  }
  .wrapper {
    padding: 35px 22px 0px;
  }
  .logo {
    width: 90%;
  }
  .step_confirmation {
    padding-left: 0;
    padding-right: 0;
  }
  .text {
    font-size: 12px;
  }

  .form_require .placeholder_label {
    font-size: 13px;
  }
  form .instagram_input label {
    transform: translateY(150%);
    top: -50%;
  }
  .form_step_second {
    width: 100%;
  }
  .form_step_third {
    padding: 35px 22px 0px;
  }
  .form_third_padding {
    padding-left: 0;
    padding-right: 0;
  }
  .red_star_self {
    margin-left: 80px !important;
  }
  .title_stepfive {
    font-size: 19px;
  }

  .sub_title_five {
    font-size: 15px;
  }

  .form_require .placeholder_label {
    margin: 14px 11px;
  }

  .form_require .instagram_input label {
    margin: 10px 11px;
  }

  .form_require .require_phone_number {
    right: calc(100% - 118px) !important;
  }

  .instagramHandlePlaceholder,
  .referrerPlaceholder {
    margin-top: 11px !important;
    margin-bottom: 11px !important;
  }

  .step_second,
  .step_third,
  .step_fourth,
  .step_confirmation {
    height: 981px !important;
  }
}

.step_second,
.step_third,
.step_confirmation {
  height: 100vh;
}

.Loading {
  color: white;
  display: flex;
  height: 20vh;
  justify-content: center;
  align-items: center;
}

.h2Modal {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.pModal {
  text-align: center;
  margin: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: #515151;
}
