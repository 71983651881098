@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700);
@import url(https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css);
@font-face {
  font-family: 'Didot-Regular';
  src: url(/static/media/GFSDidot-Regular.73134277.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  background: black;
}

h1 {
  line-height: 1.28em;
  font-size: 32px;
  font-weight: 500;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf)
    format('truetype');
  font-style: normal;
  font-weight: 300;
}

.styles_step_second__2fvyS,
.styles_step_third__sDbRi,
.styles_step_fourth__2O5wX,
.styles_step_five__8yN0A,
.styles_step_confirmation__ejhMi,
.styles_success__2ypRq {
  color: white;
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  height: auto;
}

.styles_step_five_action_container__TYRfO {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 33px 10px;
}

@media screen and (max-width: 1024px) {
  .styles_step_second__2fvyS,
  .styles_step_third__sDbRi,
  .styles_step_fourth__2O5wX {
    background-size: cover;
  }
}

.styles_step_second_wrapper__3ZU41 {
  width: 380px;
  padding-top: 70px;
}

.styles_title__tHArZ {
  font-size: 30px;
}

.styles_title_stepfive__4hBbW {
  font-size: 21.5px;
}

.styles_title_confirmation__2bewF {
  font-size: 24px;
}

.styles_sub_title__ZiVQN {
  font-size: 14px;
}

.styles_sub_title_step_third__1eTt1 {
  font-size: 19px;
  padding: 12px 0;
}

.styles_sub_title_five__2LrqJ {
  font-size: 16px;
}

.styles_sub_title_confirmation__f4pAa {
  font-size: 16px;
}

.styles_logo__1u22x {
  width: 346px;
  margin: auto;
  margin-bottom: 32px;
}

form {
  margin: 24px auto 33px;
  width: 354px;
}
.styles_step_confirmation__ejhMi form {
  width: 320px;
}

form .styles_instagram_input__I9OxD {
  position: relative;
  height: 48px;
  margin-bottom: 25px;
}

form .styles_instagram_input__I9OxD span {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
}

.styles_red_star_self__1gvqW {
  margin-left: 88px !important;
}
.styles_red_star_referrer__c_DPV {
  display: none;
}

.styles_tool_tip__2g4HJ {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

form .styles_instagram_input__I9OxD label {
  position: absolute !important;
  margin-left: 55px !important;
}

form .styles_instagram_input__I9OxD .styles_input_prefix__3KYOe {
  position: absolute !important;
  left: 40px !important;
}

.styles_footer__3XsxU {
  font-size: 11px;
  line-height: 19px;
  margin-top: 33px;
  padding-bottom: 60px;
}

.styles_footer__3XsxU a {
  text-decoration: underline;
  font-weight: 700;
}

.styles_step_second__2fvyS form > div {
  margin-bottom: 15px;
}

.styles_step_third__sDbRi form .styles_wrap_places_auto__saC4n {
  position: relative;
}

.styles_step_third__sDbRi form .styles_places_auto_input__oH81m {
  padding: 12px 20px;
  border: 1px solid rgba(255, 255, 255, 0.17);
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  width: 100%;
  background: #1a1a1a;
  margin: 12.5px 0;
  color: white;
  font-weight: 400;
}

.styles_step_third__sDbRi form .styles_places_auto_input__oH81m::placeholder {
  color: white;
}

.styles_step_third__sDbRi form .styles_menu__2bdZb {
  border-radius: 10px;
  top: 62px;
  z-index: 2;
  text-align: left;
}

.styles_step_third__sDbRi form > div:nth-child(n + 1) {
  cursor: pointer;
}

.styles_step_third__sDbRi form > div:last-child {
  margin-top: 65px;
}

.styles_step_third__sDbRi .styles_sub_title__ZiVQN {
  margin: 10px 0 15px 0;
}

.styles_step_fourth__2O5wX {
  background-position-x: 0;
  height: 100vh;
}

.styles_step_fourth__2O5wX .styles_wrap_instagram__1vptu,
.styles_step_confirmation__ejhMi .styles_wrap_instagram__1vptu {
  display: flex;
  background: white;
  align-items: center;
  color: black;
  padding: 5px 13px;
  border-radius: 27px;
  font-family: "Open Sans";
  font-weight: bold;
  margin: 52px auto 0 auto;
  cursor: pointer;
  font-size: 14px;
}

.styles_wrap_connect_instagram__31OEi {
  max-width: 223px;
  width: 100%;
}

.styles_wrap_confirm_instagram__28GCd {
  max-width: 250px;
  width: 100%;
}

.styles_step_fourth__2O5wX .styles_wrap_instagram__1vptu div {
  text-align: left;
}

.styles_step_fourth__2O5wX .styles_wrap_instagram__1vptu div,
.styles_step_confirmation__ejhMi .styles_wrap_instagram__1vptu div {
  margin: auto 9px;
}

.styles_step_fourth__2O5wX .styles_content__38rhx {
  margin: 33px;
}

.styles_step_five__8yN0A .styles_list_image__1pVS4 {
  max-height: 358px;
  width: 100%;
  background: black;
  margin: 33px 0;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 1.5%;
}

.styles_step_five__8yN0A .styles_notEnoughImages__1iVJF {
  padding: 1.5%;
}

.styles_step_five__8yN0A .styles_list_image_active_next_page__pCJqp {
  position: relative;
}

.styles_step_five__8yN0A .styles_list_image_active_next_page__pCJqp::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.styles_step_five__8yN0A .styles_list_image_active_next_page__pCJqp::before {
  content: "";
  width: 50px;
  height: 50px;
  border: 4px solid #d3d3d3;
  border-top-color: #778899;
  border-radius: 50%;
  animation: styles_spin__3C1KV 1s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
}

@keyframes styles_spin__3C1KV {
  to {
    transform: rotate(360deg);
  }
}

.styles_step_five__8yN0A .styles_list_image__1pVS4::-webkit-scrollbar {
  width: 6px;
}

.styles_step_five__8yN0A .styles_list_image__1pVS4::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 3px;
}

.styles_step_five__8yN0A .styles_list_image__1pVS4 .styles_image__1RxY8 {
  height: 30%;
  width: 30%;
  margin: 1.5%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.styles_step_five__8yN0A .styles_list_image__1pVS4 .styles_image__1RxY8 img {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.styles_step_five__8yN0A .styles_list_image__1pVS4 .styles_image__1RxY8 .styles_selected_image__2PecS {
  position: absolute;
  width: 50px;
  margin: auto;
  left: 0;
  right: 0;
  margin: auto;
}

.styles_step_five__8yN0A .styles_button_submit__R56ai[disabled] {
  background: #1d1d1f;
  border: none;
  pointer-events: none;
}

.styles_step_fourth__2O5wX,
.styles_step_confirmation__ejhMi,
.styles_success__2ypRq {
  background-size: cover !important;
}

.styles_success__2ypRq {
  height: 100vh;
}

.styles_step_confirmation__ejhMi > div {
  width: 320px !important;
}

.styles_step_confirmation__ejhMi .styles_sub_title__ZiVQN {
  margin: 33px 0;
}

.styles_step_confirmation__ejhMi .styles_resend_verify__1R6Ly {
  border: 1px solid white;
  padding: 8px 14px;
  border-radius: 27px;
  cursor: pointer;
  margin: 0 10%;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  max-width: 210px;
  width: 100%;
  color: white;
}

.styles_step_confirmation__ejhMi .styles_resend_verify_disable__uKSjl {
  border: 1px solid gray;
  padding: 8px 14px;
  border-radius: 27px;
  cursor: pointer;
  margin: 0 10%;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  max-width: 210px;
  width: 100%;
  color: gray;
}

.styles_sms_verify_disable__20fpT div input,
.styles_email_verify_disable__1KZ2o div input {
  border-radius: 10px !important;
  height: 45px !important;
  width: 42px !important;
  background: #1a1a1a !important;
  border: 1px solid rgba(255, 255, 255, 0.17) !important;
  outline: none !important;
}

.styles_sms_verify__3Bg4D,
.styles_email_verify__Wxeyj {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.styles_no_margin__3jHnk {
  margin: 0px !important;
}

.styles_step_confirmation__ejhMi .styles_wrap_sms_verify__1aY50,
.styles_wrap_email_verify__2xEUM {
  margin: 30px 0px 38px;
  position: relative;
}

.styles_step_confirmation__ejhMi .styles_wrap_email_verify__2xEUM span,
.styles_step_confirmation__ejhMi .styles_wrap_sms_verify__1aY50 span {
  transform: translate(-290%, 0%);
}

.styles_step_confirmation__ejhMi .styles_follow_instagram__3WjtZ {
  display: flex;
  justify-content: space-between;
}

.styles_step_confirmation__ejhMi .styles_wrap_sms_verify__1aY50 .styles_sms_verify__3Bg4D input,
.styles_step_confirmation__ejhMi .styles_wrap_email_verify__2xEUM .styles_email_verify__Wxeyj input {
  width: 43px !important;
  height: 46px !important;
  margin-bottom: 9px !important;
  padding: 12px 10px;
  text-align: center;
}

.styles_step_confirmation__ejhMi .styles_sms_checked__15QvV,
.styles_step_confirmation__ejhMi .styles_follow_checked__3Fv4S {
  background: none;
  position: absolute;
  right: -2%;
  top: 33%;
}

.styles_display_none__1hq3W {
  display: none !important;
}

.styles_wrap_sms_verify__1aY50 .styles_checked_confirmation__3ZcoV,
.styles_wrap_email_verify__2xEUM .styles_checked_confirmation__3ZcoV,
.styles_follow_instagram__3WjtZ .styles_follow_checked__3Fv4S {
  border: none;
}

.styles_step_confirmation__ejhMi .styles_follow_checked__3Fv4S {
  top: 23%;
}

.styles_step_confirmation__ejhMi .styles_sms_checked__15QvV input,
.styles_step_confirmation__ejhMi .styles_follow_checked__3Fv4S input {
  width: 100% !important;
  top: 18px;
  left: 8px;
}

.styles_step_confirmation__ejhMi .styles_email_verify__Wxeyj input:last-child {
  margin-bottom: auto;
}

.styles_step_confirmation__ejhMi .styles_wrap_instagram__1vptu {
  margin-bottom: 0;
}

.styles_step_confirmation__ejhMi .styles_follow_instagram__3WjtZ {
  position: relative;
  justify-content: center;
}

.styles_success__2ypRq .styles_back_home__fD-gW {
  padding: 8px 14px;
  width: auto;
}

.styles_confirm_block_container__3ueHV {
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
  width: 340px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
  margin-bottom: 25px;
}

.styles_container__3ClRW {
  padding-left: 50px;
  cursor: pointer;
  font-size: 22px;
  width: 0px;
}

.styles_input__c3nhg {
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 20px;
  color: #ffffff;
  width: 340px;
  outline: none;
  margin-bottom: 25px;
}

.styles_input_prefix__3KYOe {
  margin-left: 10px;
}

.styles_input_group_in_input__1GDzX {
  border-radius: 10px !important;
  padding-left: 66px;
  border-radius: unset;
  font-size: 14px;
}

.styles_input_icons__1hueb {
  font-size: 14px;
}
.styles_input__c3nhg::placeholder,
.styles_combobox_phone_number__1y5ak input::placeholder {
  color: #ffffff;
}
.styles_input__c3nhg:last-child {
  margin-bottom: 0;
}

.styles_input_group__gTvI7 {
  display: flex;
  align-items: center;
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 20px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 25px;
}

.styles_input_group__gTvI7::placeholder {
  color: #ffffff;
}

.styles_input_group__gTvI7:focus {
  outline: none;
}

input:last-child {
  margin-bottom: 0;
}

.styles_description_tooltip__3H67O {
  background: #000000;
  border: 1px solid #979797;
  width: -webkit-max-content;
  width: max-content;
  max-width: 300px;
  max-height: 260px;
  border-radius: 5px !important;
}
.styles_description_tooltip__3H67O p {
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}
.styles_description_tooltip__3H67O::before {
  border-top: 8px solid #979797;
}

.styles_wrap_phone_number__2I56K {
  margin-bottom: 25px;
  background: #1a1a1a;
  border-radius: 10px;
  position: relative;
}

.styles_wrap_phone_number__2I56K label {
  position: absolute;
  right: 20px;
  top: 12px;
}

.styles_confirm_block_container__3ueHV {
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
  width: 100%;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
  margin-bottom: 25px;
}

.styles_wrap_checkbox__3TZ6U {
  padding-left: 50px;
  cursor: pointer;
  font-size: 22px;
  width: 0px;
}

.styles_wrap_checkbox_input__2oDDm {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.styles_check_mark__1uLeD {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: 20px;
  height: 24px;
  width: 24px;
  border: 1px solid #979797;
  border-radius: 15px;
}

input:checked ~ .styles_check_mark__1uLeD::after {
  display: block;
}

.styles_check_mark__1uLeD::after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.styles_text__uAVpG {
  font-size: 12px;
  line-height: 16px;
  align-items: center;
}

.styles_text_span__2EaxP {
  font-weight: 700;
  text-decoration: underline;
}

.styles_button__TwByC {
  background: #ffffff;
  border-radius: 27px;
  color: #000000;
  width: 100px;
  height: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  border: none;
  font-weight: 700;
}

.styles_button__TwByC:focus {
  outline: none;
}

.styles_form_require__1leIR {
  width: 340px;
  display: block;
  position: relative;
  box-sizing: border-box;
}

.styles_form_require__1leIR input {
  box-sizing: border-box;
}

.styles_form_require__1leIR input:-webkit-autofill,
.styles_form_require__1leIR input:-webkit-autofill:hover,
.styles_form_require__1leIR input:-webkit-autofill:focus {
  -webkit-text-fill-color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.166603) !important;
  box-shadow: 0 0 0px 1000px #1a1a1a inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset !important;
  background-clip: content-box;
}

.styles_form_require__1leIR input[type="submit"] {
  width: auto;
}

.styles_form_require__1leIR .styles_placeholder_label__3VdvJ {
  pointer-events: none;
  margin: 12px 11px;
  padding: 0;
  white-space: nowrap;
  color: #999;
  left: 10px;
  color: #ffffff;
  position: absolute;
}
.styles_instagramHandlePlaceholder__1zc_c,
.styles_instagramHandleInput__1N4bp,
.styles_referrerInput__11PaR,
.styles_referrerPlaceholder__2u_FR {
  font-size: 14px;
}

.styles_instagramHandlePlaceholder__1zc_c,
.styles_referrerPlaceholder__2u_FR {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.styles_form_require__1leIR .styles_placeholder_label__3VdvJ span {
  color: #e4293a;
}

.styles_combobox_phone_number__1y5ak > div {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.166603);
  border-radius: 10px;
}

.styles_combobox_phone_number__1y5ak > div > div {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 20px;
}

.styles_combobox_phone_number__1y5ak select {
  background-color: transparent;
  border: none;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  outline: none;
  position: absolute;
  width: 100%;
  opacity: 0;
}

.styles_combobox_phone_number__1y5ak option {
  color: #000000;
}

.styles_combobox_phone_number__1y5ak input {
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  width: 100%;
  background: #1a1a1a;
  width: 100%;
}

.styles_combobox_phone_number__1y5ak > div > div > div:last-child {
  display: block;
  content: "";
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 10px;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;

  opacity: 0.7;
  font-size: 16px;
  transition: color 0.1s;
}

.styles_disabled_btn__3v_Y1 {
  background: transparent !important;
  color: white !important;
  border: 1px solid white !important;
  opacity: 0.5;
}

.styles_wrapper_button__2T17n {
  margin-top: 47px;
}

.styles_h1__3Svhr {
  text-align: center;
  color: white;
  font-size: 35px;
  font-weight: 900;
  margin: 10px 0;
}

.styles_h2__1-cGJ {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.styles_p__1fK7h {
  text-align: center;
  margin: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: white;
}

.styles_p__1fK7h .styles_b__Dj6JB {
  font-weight: 900;
  color: #000;
}

.styles_check__3spoF {
  width: 75px;
  margin: 10px;
}

.styles_ModalBackground__2uU8l {
  height: auto !important;
  background-color: rgb(147, 150, 152) !important;
  border-radius: 10px;
  width: auto !important;
}

.styles_ModalContent__3grHJ {
  padding: 0px !important;
  text-align: center;
}

.styles_ModalSelectButton__1EnN3 {
  min-width: 200px !important;
  margin: auto !important;
}

.styles_CodeInput__hXMtz {
  margin-top: 17px;
}

.styles_CodeInput__hXMtz input[type="number"]::-webkit-outer-spin-button,
.styles_CodeInput__hXMtz input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.styles_danger_color__23D4p input[type="number"] {
  border-color: #e4293a !important;
}

.styles_CodeInput__hXMtz input[type="number"] {
  -moz-appearance: textfield;
  background: #1A1A1A;
  border: 1px solid rgba(255, 255, 255, 0.17);
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.styles_CheckContainer__18qd4 {
  padding: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;

  display: flex;
  align-items: center;
}

.styles_Check__a5azn {
  height: 20px;
}

.styles_ErrorInfo__O9fNN {
  color: #e04344;
}

.styles_InputError__1eEgD {
  border: 1px solid #e04344;
}

.styles_step_confirmation__ejhMi .styles_in_valid_code__3wMPN span {
  border-color: #e4293a;
}

.styles_location_rows__yWAi_ {
  margin-bottom: 15px;
}

.styles_location_rows__yWAi_ > div:first-child {
  margin-right: 10.5px;
}

.styles_location_rows__yWAi_ > div:last-child {
  margin-left: 10.5px;
}

.styles_error_input__3TBQF {
  border: 1px solid #e4293a;
}

.styles_error_input_phone__1aihz > div {
  border: 1px solid #e4293a;
}

.styles_error_form__16Cvd {
  color: white;
  font-size: 13px;
  font-weight: bold;
  left: 0;
  right: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  text-transform: uppercase;
}

.styles_error_form_stepconfirmation__1NkPN {
  height: 36px;
  width: 221px;
  margin-top: 9px;
}

.styles_error_form_stepconfirmation__1NkPN .styles_warning_icon__3Vi6j {
  margin-right: 21px;
  transform: translate(0%, 0%) !important;
}
.styles_error_form_stepconfirmation__1NkPN .styles_warning_msg__2yfUg {
  height: 36px;
  width: 176px;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.styles_warning_icon__3Vi6j {
  margin-right: 16px;
}

.styles_require_phone_number__YZh1F {
  color: #e4293a;
  width: 0;
  height: 0;
}

@media screen and (max-width: 767px) {
  .styles_step_first__3Ng7q .styles_confirm_block_container__3ueHV {
    padding: 12px 16px;
  }
  .styles_step_first__3Ng7q .styles_wrap_checkbox__3TZ6U {
    padding-left: 38px;
  }
  .styles_step_second__2fvyS {
    background-image: url(/static/media/afro.db54ad2e.png) !important;
  }
}

@media screen and (max-width: 445px) {
  .styles_form_require__1leIR input[required]:invalid + label {
    font-size: 12px;
    margin: 15px 11px;
  }
  .styles_step_first__3Ng7q .styles_wrap_phone_number__2I56K {
    font-size: 12px;
  }
}

.styles_wrap_autocomplete__-UsEd {
  position: relative;
}

.styles_wrap_autocomplete__-UsEd label {
  position: absolute;
  top: 25px;
  color: #e4293a;
  width: 0;
  height: 0;
  right: calc(100% - 218px);
}

.styles_step_five__8yN0A .styles_image_count__3Ojko {
  font-size: 15px;
}

.styles_step_first__3Ng7q .styles_error_form__16Cvd {
  position: relative;
  margin-bottom: 15px;
  display: flex;
}

.styles_step_five__8yN0A .styles_title__tHArZ {
  margin-bottom: 12px;
}

.styles_error_message__1aAvx {
  color: #e4293a;
}

.styles_success__2ypRq .styles_footer__3XsxU {
  margin-top: 297px;
}
@media screen and (max-width: 500px) {
}

@media screen and (max-width: 440px) {
  .styles_step_second_wrapper__3ZU41 {
    padding: 35px 0px 0px;
  }
  .styles_wrapper__LEjax {
    padding: 35px 22px 0px;
  }
  .styles_logo__1u22x {
    width: 90%;
  }
  .styles_step_confirmation__ejhMi {
    padding-left: 0;
    padding-right: 0;
  }
  .styles_text__uAVpG {
    font-size: 12px;
  }

  .styles_form_require__1leIR .styles_placeholder_label__3VdvJ {
    font-size: 13px;
  }
  form .styles_instagram_input__I9OxD label {
    transform: translateY(150%);
    top: -50%;
  }
  .styles_form_step_second__2VJfQ {
    width: 100%;
  }
  .styles_form_step_third__1qzba {
    padding: 35px 22px 0px;
  }
  .styles_form_third_padding__1-PtQ {
    padding-left: 0;
    padding-right: 0;
  }
  .styles_red_star_self__1gvqW {
    margin-left: 80px !important;
  }
  .styles_title_stepfive__4hBbW {
    font-size: 19px;
  }

  .styles_sub_title_five__2LrqJ {
    font-size: 15px;
  }

  .styles_form_require__1leIR .styles_placeholder_label__3VdvJ {
    margin: 14px 11px;
  }

  .styles_form_require__1leIR .styles_instagram_input__I9OxD label {
    margin: 10px 11px;
  }

  .styles_form_require__1leIR .styles_require_phone_number__YZh1F {
    right: calc(100% - 118px) !important;
  }

  .styles_instagramHandlePlaceholder__1zc_c,
  .styles_referrerPlaceholder__2u_FR {
    margin-top: 11px !important;
    margin-bottom: 11px !important;
  }

  .styles_step_second__2fvyS,
  .styles_step_third__sDbRi,
  .styles_step_fourth__2O5wX,
  .styles_step_confirmation__ejhMi {
    height: 981px !important;
  }
}

.styles_step_second__2fvyS,
.styles_step_third__sDbRi,
.styles_step_confirmation__ejhMi {
  height: 100vh;
}

.styles_Loading__3Zx6D {
  color: white;
  display: flex;
  height: 20vh;
  justify-content: center;
  align-items: center;
}

.styles_h2Modal__y2HsD {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.styles_pModal__1Im2r {
  text-align: center;
  margin: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: #515151;
}

.styles_footer__3Ku6b {
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  padding: 100px 0px 0px;
}

.styles_logo__3GEat {
  margin: 0 auto;
  max-width: 380px;
}

.styles_title__pgQrp {
  font-size: 24px;
  line-height: 29px;
}

.styles_form__3Hgen {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 33px;
  margin-bottom: 19px;
}

.styles_text_information__3PMzj {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  opacity: 0.53;
  margin: 22px;
}

.styles_footer_bottom__3oVvz {
  background-color: #1d1d1f;
  padding: 15px 0px;
  margin-top: 30px;
}

.styles_footer_bottom_wrap__3eQom {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 466px;
  margin: 0 auto;
}

.styles_footer_bottom_wrap_text__2lfgf {
  text-align: left;
  font-size: 10px;
  line-height: 19px;
  margin-right: 29px;
  width: 337px;
}

.styles_footer_bottom_wrap_text__2lfgf a {
  text-decoration: underline;
  font-weight: 700;
}

.styles_footer_bottom_wrap_button__1B6kD,
.styles_button__3H6NA {
  background: #ffffff;
  border-radius: 27px;
  color: #1d1d1f;
  font-weight: bold;
  line-height: 16px;
  cursor: pointer;
  font-weight: 700;
}

.styles_footer_bottom_wrap_button__1B6kD {
  width: 100px;
  font-size: 12px;
  text-align: center;
  padding: 7px 8px;
  height: 30px;
}

.styles_contact_us_button_container__37Eyr {
  display: flex;
  justify-content: center;
}

.styles_contact_us_button_container__37Eyr p {
  font-size: 12px;
  line-height: 19px;
  font-family: "Open Sans";
}

.styles_need_help__22tS4 {
  font-size: 12px;
  line-height: 19px;
  padding: 100px 0px;
  font-family: "Open Sans";
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 767px) {
  .styles_footer__3Ku6b {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 440px) {
  .styles_footer__3Ku6b {
    padding-top: 36px;
  }
  .styles_title__pgQrp {
    font-size: 20px;
  }
  .styles_footer__3Ku6b .styles_form__3Hgen {
    margin-top: 0;
    width: calc(100% - 34px);
    margin: 0 auto;
  }
  .styles_form__3Hgen form {
    width: 100%;
  }
  .styles_form__3Hgen input {
    width: 100%;
    font-size: 13px;
  }
  .styles_logo__3GEat {
    max-width: 320px;
  }
  .styles_text_information__3PMzj {
    font-size: 13px;
    max-width: 235px;
    margin: 22px auto;
  }
  .styles_footer_bottom__3oVvz {
    display: none;
  }
  .styles_logo__3GEat {
    max-width: 290px;
  }
}

.styles_main__1trKM {
  position: relative;
}

.styles_for_mobile__2NUlC,
.styles_is_mobile__VOEGG {
  display: none;
}

.styles_banner__2SeIJ {
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
}

.styles_first_banner__3jStb {
  /*background-image: url("../../../assets/images/header_bg_2.png");*/
  height: 110vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.styles_header_link__1eWXe {
  display: flex;
  justify-content: center;
}

.styles_header_link_styles__2nvIn {
  position: relative;
  padding: 5px 20px;
  width: 140px;
  height: 35px;
  border: 1.2px solid white;
  border-radius: 27px;
  color: white;
  text-decoration: none;
  margin: 34px 10px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}
button:focus {
  outline: none;
}

.styles_header_link_child_1__1SStK {
  background: white;
  color: black;
}

.styles_header_logo_wapper__1ohEH {
  position: absolute;
  /* bottom: 100px; */
  left: 50%;
    transform: translate(-50%);
    height: 50vh;
    align-items: center !important;
    /* justify-content: center !important; */
    display: flex !important
}

.styles_header_logo__hWil0 {
  margin-top: 503px;
}

.styles_header_logo__hWil0 img {
  max-width: 590px;
}

.styles_header_cylinder__2fYss {
  height: 203px;
  display: flex;
  justify-content: center;
}

.styles_second_banner__3CqKF {
  background-image: url(/static/media/i_stock_2.7fb7df4e.png);
  max-height: 863px;
  padding-bottom: 127px;
  width: 100%;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  margin-top: -60px;
}

.styles_second_banner_bg1__366Ky {
  opacity: 0.67;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.styles_content_shoot_to_kill__2zjUU {
  display: flex;
  justify-content: center;
  color: white;
}

.styles_content_shoot_to_kill_div__h8pnU {
  margin-top: 100px;
  text-align: center;
}

.styles_content_shoot_to_kill_h1__1gdaN {
  font-size: 70px;
  font-family: "Didot";
}

.styles_content_shoot_to_kill_p__17Bpp {
  font-size: 30px;
  text-align: center;
  font-family: "Didot";
  margin-top: 10px;
}

.styles_shoot_to_kills__2tHX5 {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1060px;
  width: 100%;
  margin-top: 95px;
  margin-bottom: 162px;
}

.styles_shoot_to_kills_div__2wfmL {
  background: black;
  color: #ffffff;
  padding-bottom: 10px;
  width: 290px;
  height: -webkit-max-content;
  height: max-content;
}

.styles_shoot_to_kills__2tHX5 img {
  width: 290px;
}

.styles_shoot_to_kills__2tHX5 div:nth-child(2) > img {
  margin-top: -16px;
}

.styles_shoot_to_kills_div__2wfmL p {
  text-align: center;
  font-family: Didot;
  font-size: 21px;
}

.styles_shoot_to_kills__2tHX5 div:nth-child(1) p {
  padding: 13px 0px 20px 0px;
}

.styles_shoot_to_kills__2tHX5 div:nth-child(2) p {
  padding: 8px 0px 20px 0px;
}

.styles_shoot_to_kills__2tHX5 div:nth-child(3) p {
  padding: 4px 35px 20px 35px;
}

.styles_third_banner__3bk7i {
  display: flex;
  justify-content: center;
}

.styles_third_banner_img__29UL6 {
  max-height: 642px;
  width: auto;
  border-radius: 0 0 10px 10px;
}

.styles_fourth_banner__19OmW {
  display: flex;
  justify-content: center;
  padding-top: 5%;
}

.styles_fourth_banner_wrap_content__Vgxrw {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
}

.styles_fourth_banner_wrap_content_to_content__1qmww {
  color: white;
  font-size: 17px;
}

.styles_fourth_banner_wrap_content_to_content__1qmww > div {
  margin: 30px 0;
}

.styles_fourth_banner_wrap_content_to_content__1qmww > div:last-child {
  margin-bottom: 0;
}

.styles_fourth_banner_wrap_content_to_content_div_p_child_1__KLnzU {
  margin: 5px 0;
  font-weight: 700;
}

.styles_fourth_banner_wrap_content_to_content_div_p_child_2__1qL_t {
  font-weight: 300;
}

.styles_fourth_banner_wrap_content_to_content__1qmww > p {
  font-family: "Didot-Regular";
}

.styles_fourth_banner_wrap_content_to_content_title__1WyBZ {
  font-size: 70px;
  font-family: "Didot-Regular";
}

.styles_fourth_banner_wrap_images__1UbDA {
  width: 65%;
  margin-bottom: -3%;
}

.styles_fourth_banner_wrap_images_first_block__26lx- {
  display: flex;
  position: relative;
  top: 13px;
  left: 138px;
  width: 90%;
}

.styles_fourth_banner_wrap_images_first_block_block_1__2P0C5 {
  width: 40.2%;
  z-index: 2;
}

.styles_for_desktop__1xnK8 {
  z-index: 3;
}

.styles_fourth_banner_wrap_images_first_block_block_1_div__3NuJZ {
  background: #414449;
  text-align: left;
  color: white;
  padding: 5% 12%;
  font-size: 15px;
  font-weight: 300;
}

.styles_fourth_banner_wrap_images_first_block_block_2_img__3aiMp {
  margin-left: -20%;
  margin-top: 7%;
}

.styles_fourth_banner_wrap_images_first_block_block_2_p__2VqDT {
  font-size: 26px;
  color: white;
  margin: 10px 40px;
  text-align: left;
  font-family: "Didot";
}
.styles_we_strongly_mobile__1p9hz {
  display: none;
}
.styles_fourth_banner_wrap_images_second_block__2Loly {
  display: flex;
}
.styles_fourth_banner_wrap_images_second_block__2Loly img {
  z-index: 1;
  height: 370px;
  width: auto;
}

.styles_fourth_banner_wrap_images_second_block_content_left__1DB61 {
  margin-left: -38%;
  z-index: 2;
  font-size: 25px;
  width: 38%;
}

.styles_fourth_banner_wrap_images_second_block_content_left_div_child_1__1mlja {
  margin: 38px 0 38px;
  font-size: 25px;
  font-family: "Didot";
}

.styles_fourth_banner_wrap_images_second_block_content_left_div_child_2__gA1k2 {
  background: #414449;
  margin-right: -20px;
  color: white;
  padding: 40px 26% 40px 13%;
  font-size: 15px;
  font-weight: 300;
}
.styles_fourth_banner_wrap_images_second_block_content_left_div_child_2_mobil__1wxQT {
  display: none;
}

@media all and (max-width: 1450px) {
  .styles_second_banner__3CqKF {
    background-size: 100% 121%;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
  .styles_second_banner__3CqKF {
    background-position: 70%;
    padding-bottom: 150px;
    background-size: cover;
  }
  .styles_second_banner__3CqKF .styles_shoot_to_kills__2tHX5 {
    max-width: 93%;
  }
  .styles_third_banner__3bk7i img {
    margin-top: -13%;
    z-index: 1;
    margin-bottom: 10%;
  }
  .styles_fourth_banner__19OmW {
    padding-top: 0;
    margin-left: 20px;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_content_to_content__1qmww > div {
    margin: 20px 0;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_content_to_content_div_p_child_1__KLnzU {
    margin-bottom: 10px;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images__1UbDA {
    width: 60%;
    top: 10px;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block__26lx- {
    top: 10px;
    left: 20px;
    width: 95%;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block_block_1__2P0C5 {
    width: 100%;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block_block_1_div__3NuJZ {
    padding: 15px 21px 8px 21px;
  }
  .styles_fourth_banner__19OmW .styles_for_desktop__1xnK8 {
    width: 100%;
    height: 30.7%;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block_block_2_img__3aiMp {
    margin-top: 10%;
    margin-left: -56px;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block_block_2_p__2VqDT {
    margin: 5% 10%;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_second_block_content_left__1DB61 {
    margin-left: -44%;
    width: 100%;
  }
  .styles_fourth_banner__19OmW
    .styles_fourth_banner_wrap_images_second_block_content_left_div_child_2__gA1k2 {
    position: relative;
    padding: 30px 27px 20px 27px;
    bottom: -15px;
    margin-right: auto;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .styles_header_logo__hWil0 img {
    max-width: 442px;
  }

  .styles_header_cylinder__2fYss {
    height: 160px;
  }

  .styles_first_banner__3jStb .styles_is_mobile__VOEGG {
    display: none;
  }

  .styles_first_banner__3jStb {
    background-size: cover;
    background-position: top;
    height: 110vh;
  }

  .styles_first_banner__3jStb .styles_header_link__1eWXe .styles_header_link_styles__2nvIn {
    margin-top: 34px;
    z-index: 1;
  }

  .styles_second_banner__3CqKF {
    background-position: 100%;
    padding-bottom: 80px;
    background-size: cover;
  }

  .styles_second_banner__3CqKF .styles_shoot_to_kills__2tHX5 {
    max-width: 89%;
    margin-top: 31px;
    margin-bottom: 32px;
  }

  .styles_second_banner__3CqKF .styles_content_shoot_to_kill_div__h8pnU {
    margin-top: 70px;
  }

  .styles_second_banner__3CqKF .styles_shoot_to_kills_div__2wfmL {
    max-width: 187px;
    max-height: 163px;
  }

  .styles_second_banner__3CqKF .styles_shoot_to_kills_div__2wfmL p {
    font-size: 10px;
  }

  .styles_second_banner__3CqKF .styles_shoot_to_kills__2tHX5 div:nth-child(2) > img {
    margin-top: -10px;
  }

  .styles_second_banner__3CqKF .styles_content_shoot_to_kill_h1__1gdaN {
    font-size: 45px;
  }

  .styles_second_banner__3CqKF .styles_content_shoot_to_kill_p__17Bpp {
    font-size: 20px;
  }

  .styles_second_banner__3CqKF .styles_shoot_to_kills__2tHX5 div:nth-child(1) p {
    padding: 13px 20px 20px 20px;
  }

  .styles_second_banner__3CqKF .styles_shoot_to_kills__2tHX5 div:nth-child(2) p {
    padding: 9px 20px 20px 20px;
  }

  .styles_second_banner__3CqKF .styles_shoot_to_kills__2tHX5 div:nth-child(3) p {
    padding: 6px 20px 20px 20px;
  }

  .styles_third_banner__3bk7i .styles_third_banner_img__29UL6 {
    max-height: 481px;
    max-width: 432px;
    z-index: 1;
    margin-bottom: 87px;
    margin-top: -86px;
  }

  .styles_fourth_banner__19OmW {
    padding-top: 0;
    margin-left: 20px;
  }

  .styles_fourth_banner__19OmW
    .styles_fourth_banner_wrap_content_to_content__1qmww
    .styles_fourth_banner_wrap_content_to_content_title__1WyBZ {
    font-size: 52px;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_content_to_content__1qmww > p {
    font-size: 22px;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_content_to_content__1qmww > div {
    margin: 20px 0;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_content_to_content_div_p_child_1__KLnzU {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_content_to_content_div_p_child_2__1qL_t {
    font-size: 12px;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images__1UbDA {
    width: 60%;
    top: 10px;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block__26lx- {
    top: 10px;
    left: 20px;
    width: 95%;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block_block_1__2P0C5 {
    width: 98%;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block_block_1_div__3NuJZ {
    padding: 15px 21px 8px 21px;
    font-size: 11.25px;
  }

  .styles_fourth_banner__19OmW .styles_for_desktop__1xnK8 {
    width: 100%;
    height: 35.7%;
    max-height: none;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block_block_2_img__3aiMp {
    margin-top: 10%;
    margin-left: -56px;
    max-width: 271px;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_first_block_block_2_p__2VqDT {
    font-size: 19.5px;
    margin: 0;
    margin-left: 22.5px;
  }

  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_second_block_content_left__1DB61 {
    margin-left: -44%;
    width: 100%;
  }

  .styles_fourth_banner__19OmW
    .styles_fourth_banner_wrap_images_second_block_content_left_div_child_1__1mlja {
    font-size: 18.75px;
    margin: 40px 0 10px 0;
    font-weight: 700;
  }

  .styles_fourth_banner__19OmW
    .styles_fourth_banner_wrap_images_second_block_content_left_div_child_2__gA1k2 {
    position: relative;
    font-size: 11.25px;
    padding: 30px 27px 20px 27px;
    bottom: -15px;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .styles_main__1trKM {
    width: 100%;
  }
  .styles_banner__2SeIJ {
    background-size: 140%;
    background-position: top;
    position: relative;
  }
  .styles_header_cylinder_div__baREw {
    margin-top: 0;
  }
  .styles_header_cylinder__2fYss {
    height: 0px;
    display: block;
    position: absolute;
    top: 79%;
    width: 100%;
  }
  .styles_header_cylinder__2fYss img {
  }
  .styles_header_link__1eWXe {
    right: 0;
    left: 0;
    z-index: 1;
  }
  .styles_header_link_styles__2nvIn {
    width: 130px;
    font-size: 12px;
    margin: 20px 10px;
    padding: 7px 25px;
  }
  .styles_first_banner__3jStb {
    height: 110vh;
    background-position: top center;
    background-size: 100%;
  }
  .styles_first_banner__3jStb .styles_is_mobile__VOEGG {
    display: block;
  }
  .styles_first_banner__3jStb .styles_is_desktop__36uo9 {
    display: none;
  }
  .styles_first_banner__3jStb .styles_header_logo__hWil0 {
    margin-top: auto;
    display: flex;
    justify-content: center;
  }
  .styles_header_logo__hWil0 img {
    width: 370px;
    height: 117px;
    max-width: none;
  }
  .styles_fourth_banner_wrap_images_second_block_content_left_div_child_2__gA1k2 {
    margin-right: 0;
  }
  .styles_fourth_banner_wrap_images_second_block_content_left_div_child_2_desktop__3rcm2 {
    display: none;
  }
  .styles_fourth_banner_wrap_images_second_block_content_left_div_child_2_mobil__1wxQT {
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding-right: 20px !important;
    text-align: center;
  }
  .styles_fourth_banner_wrap_images_second_block_content_left_div_child_2_mobil__1wxQT
    .styles_we_believe_that_talent_mobile__ihSm9 {
    /* padding: 0 50px; */
    /* text-align: center; */
  }
  .styles_content_shoot_to_kill_h1__1gdaN {
    font-size: 50px;
    height: 108px;
  }
  .styles_content_shoot_to_kill_p__17Bpp {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
  }
  .styles_shoot_to_kills_div__2wfmL {
    font-size: 13px;
  }
  .styles_shoot_to_kills__2tHX5 {
    padding: 0 10%;
    width: 100%;
    margin: auto;
    margin-top: 18%;
    display: block;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .styles_shoot_to_kills__2tHX5 img {
    width: 100%;
  }
  .styles_second_banner__3CqKF {
    display: inline-block;
    height: 100%;
    background-size: 931px 518px;
    margin-top: 0;
    position: initial;
  }
  .styles_third_banner__3bk7i {
    background-image: url(/static/media/blondeleft.7276c813.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    max-width: 100%;
  }
  .styles_third_banner__3bk7i img {
    margin-top: 33px;
    margin-bottom: -93px;
  }
  .styles_fourth_banner__19OmW {
    display: initial;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_content__Vgxrw {
    display: block;
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_content__Vgxrw p {
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    font-family: "Didot";
  }
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_content_to_content__1qmww > p {
    line-height: 38px;
    font-size: 30px;
    font-weight: 400;
  }

  .styles_fourth_banner_wrap_content_to_content__1qmww {
    width: 100%;
    padding: 0 20px 0 20px;
    font-size: 30px;
    margin-top: 212px;
  }
  .styles_fourth_banner_wrap_content_to_content_title__1WyBZ {
    font-size: 40px;
  }
  .styles_fourth_banner_wrap_content_to_content_div_p_child_1__KLnzU {
    font-weight: 700 !important;
  }
  .styles_fourth_banner_wrap_content_to_content_div_p_child_1__KLnzU,
  .styles_fourth_banner_wrap_content_to_content_div_p_child_2__1qL_t {
    font-size: 15px !important;
    font-family: "Open Sans" !important;
  }
  .styles_fourth_banner_wrap_content_to_content_div_p_child_2__1qL_t {
    font-weight: 300 !important;
    line-height: 20px !important;
  }
  .styles_fourth_banner_wrap_images_first_block__26lx- {
    width: 100%;
    top: 34px;
    left: 0;
    z-index: 2;
    position: initial;
    margin-top: 20px;
  }
  .styles_fourth_banner_wrap_images__1UbDA {
    width: 100%;
  }
  .styles_fourth_banner_wrap_images_first_block__26lx- {
    display: block;
  }
  .styles_fourth_banner_wrap_images_first_block_block_1__2P0C5 {
    width: 100%;
    height: 100%;
  }
  .styles_fourth_banner_wrap_images_first_block_block_1__2P0C5 img {
    width: 100%;
  }
  .styles_fourth_banner_wrap_images_first_block_block_1_div__3NuJZ {
    padding: 50px 5% 20px 20px;
    position: relative;
    text-align: center;
  }
  .styles_we_strongly_desktop__2iHwK {
    display: none;
  }
  .styles_we_strongly_mobile__1p9hz {
    display: block;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
    margin-top: 15px;
  }
  .styles_we_strongly_mobile_one__3sR6t {
  }
  .styles_for_desktop__1xnK8 {
    display: none;
  }
  .styles_for_mobile__2NUlC {
    display: block;
  }
  .styles_fourth_banner_wrap_images_first_block_block_1_img__1c2_f {
    width: 240px;
    height: 83px;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
  .styles_fourth_banner_wrap_images_first_block_block_1_img__1c2_f img {
    width: 95%;
  }
  .styles_fourth_banner_wrap_images_first_block_block_2_p__2VqDT {
    font-size: 20px;
    height: 62px;
    margin: 33px 20px 25px 20px;
    line-height: 28px;
  }
  .styles_fair_access_mobile__3640W,
  .styles_available_mobile__1uayL {
    text-align: center;
    /* font-size: 22px; */
  }
  .styles_available_mobile__1uayL {
  }
  .styles_fourth_banner_wrap_images_second_block__2Loly {
    display: block;
    position: relative;
  }
  .styles_fourth_banner_wrap_images_second_block_bg__3iiON {
    background-image: url(/static/media/girlsinleather.661a946d.png);
    width: 100%;
    background-size: 120%;
    background-position: right;
    height: 202px;
    background-repeat: no-repeat;
  }
  .styles_fourth_banner_wrap_images_second_block_content_left__1DB61 {
    margin-left: 0;
    width: 100%;
  }
  .styles_fourth_banner_wrap_images_second_block_content_left_div_child_2__gA1k2 {
    padding: 20px 32% 20px 20px;
  }

  .styles_fourth_banner_wrap_images_second_block_content_left_div_child_1__1mlja {
    position: absolute;
    font-size: 15px;
    width: 141px;
    top: 0;
    right: 20px;
    display: flex;
    align-items: center;
    bottom: calc(100% - 200px);
  }
  .styles_header_cylinder__2fYss {
    top: 55%;
  }
}
@media only screen and (max-width: 400px) {
  .styles_header_cylinder__2fYss {
    top: 60%;
  }
  .styles_header_logo__hWil0 img {
    width: 290px;
    height: 92px;
    margin-bottom: 34px;
  }
  button[data-for="registerTip"] {
    display: none;
  }
}

@media only screen and (min-width: 415px) and (max-width: 767px) {
  .styles_third_banner__3bk7i {
    background-position: top center;
    background-size: contain;
    margin-top: 305px;
    background-image: none;
  }
  .styles_third_banner__3bk7i img {
    margin-bottom: 0;
    width: 100%;
    padding-left: 17px;
    padding-right: 17px;
  }
  .styles_fourth_banner_wrap_content_to_content__1qmww {
    margin-top: 112px;
  }
  .styles_fourth_banner_wrap_images_second_block_bg__3iiON {
    height: 302px;
    margin-bottom: -5%;
  }
  .styles_fourth_banner_wrap_images_second_block_content_left_div_child_1__1mlja {
    bottom: calc(100% - 73%);
  }
}
@media only screen and (min-width: 650px) and (max-width: 767px) {
}

@media only screen and (min-width: 490px) and (max-width: 767px) {
  .styles_second_banner__3CqKF .styles_shoot_to_kills__2tHX5 {
    width: 70%;
  }
}
.styles_third_banner_img_mobile__3YvmX {
  display: none;
}
@media only screen and (min-width: 415px) and (max-width: 490px) {
  .styles_second_banner__3CqKF .styles_shoot_to_kills__2tHX5 {
    width: 90%;
  }

  .styles_third_banner_img__29UL6 {
    display: none;
  }
  .styles_fourth_banner_wrap_content_to_content__1qmww {
    padding-bottom: 14px;
  }
  .styles_third_banner_img_mobile__3YvmX {
    display: block;
    width: 100%;
    padding: 0px 14px 0px 12px;
  }
  .styles_header_cylinder__2fYss {
    bottom: -160px;
  }
}

@media only screen and (min-width: 416px) {
  .styles_fourth_banner__19OmW .styles_fourth_banner_wrap_images_second_block_bg__3iiON {
    height: 218px;
    background-size: 100%;
    background-position: center;
  }
}

@media only screen and (max-width: 416px) {
  .styles_third_banner__3bk7i {
    margin-top: 250px;
  }
  .styles_third_banner__3bk7i {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 376px) and (max-width: 414px) {
  .styles_third_banner__3bk7i {
    background-image: none;
  }
  .styles_third_banner_img__29UL6 {
    display: none;
  }

  .styles_third_banner__3bk7i img {
    margin-top: 33px;
  }
  .styles_third_banner_img_mobile__3YvmX {
    display: block;
    width: 100%;
    padding: 0px 14px 0px 12px;
  }
  .styles_fourth_banner_wrap_content_to_content__1qmww {
    padding-bottom: 14px;
  }
  .styles_header_cylinder__2fYss {
    bottom: -130px;
  }
}
@media only screen and (max-width: 375px) {
  .styles_third_banner__3bk7i {
    background-image: none;
  }
  .styles_first_banner__3jStb {
    height: 95vh;
  }
  .styles_third_banner_img__29UL6 {
    display: none;
  }

  .styles_third_banner__3bk7i img {
    margin-top: 45px;
  }
  .styles_third_banner_img_mobile__3YvmX {
    display: block;
    width: 100%;
  }
  .styles_fourth_banner_wrap_content_to_content__1qmww {
    padding-bottom: 14px;
  }
  .styles_header_cylinder__2fYss {
    bottom: -100px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) { 
  .styles_first_banner__3jStb {
    background-size: contain;
    background-position: top;
    height: 110vh;
  }
}

@media only screen and (max-width: 787px) {
  .styles_first_banner__3jStb {
    background-position: top;
    background-size: 100%;
    height: 70vh !important;
  }
}

@media only screen and (max-width: 767px) {
  .styles_header_logo__hWil0 img {
    width: 370px;
    height: 100% !important;
    max-width: none;
  }
}

@media only screen and (max-width: 400px) {
  .styles_header_logo__hWil0 img {
    width: 290px;
    height: 100% !important;
    margin-bottom: 34px;
  }
}

@media only screen and (max-width: 600px) {
  .styles_header_logo__hWil0 img {
    width: 400px;
    height: 100% !important;
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 787px) {
  .styles_first_banner__3jStb {
    background-position: top;
    background-size: 100%;
    height: 70vh !important;
    margin-bottom: 200px;
  }
}
.sticky {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background: transparent;
  transition: all 0.5s;
}

.sticky.fixed {
  background: rgba(0, 0, 0, 0.9);
}

@media only screen and (max-width: 767px) {
  .sticky {
    bottom: 0;
    top: auto;
    background: rgba(0, 0, 0, 0.9);
  }
}

.styles_modal__3XL19 {
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.69);
  overflow-y: auto;
}

@media screen and (max-width: 550px) {
  .styles_modal__3XL19 {
    padding: 0 15px;
  }
}

.styles_modal__3XL19 .styles_modal_content__1e640 {
  position: relative;
  margin: 88px auto;
  max-width: 516px;
  width: 100%;
  height: auto;
  padding: 24px 75px;
  border-radius: 5px;
  border: 1px solid #979797;
  text-align: center;
  background-color: black;
  color: white;
  font-family: "Open Sans";
  -webkit-animation-name: styles_zoom__LewhZ;
  -webkit-animation-duration: 0.6s;
  animation-name: styles_zoom__LewhZ;
  animation-duration: 0.6s;
}

@media screen and (max-width: 580px) {
  .styles_modal__3XL19 .styles_modal_content__1e640 {
    padding: 24px;
  }
}

@keyframes styles_zoom__LewhZ {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.styles_close_icon__24nvQ {
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  margin: 30px;
  width: 20px;
  cursor: pointer;
}

@media screen and (max-width: 460px) {
  .styles_close_icon__24nvQ {
    margin: 15px;
    width: 15px;
  }
}

/* @media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
} */

.styles_input_group__2PNy6 {
  display: flex;
  align-items: center;
  background: #1a1a1a;
  padding: 0 20px;
  border-radius: 10px;
  margin: 12.5px 0;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.17);
}

.styles_input_group__2PNy6 input {
  margin: 0;
}

.styles_input_group__2PNy6 input {
  padding-left: 30px;
}

.styles_row__1H-Yx {
  position: relative;
}

.styles_wrap_checkbox__3ixYW {
  cursor: pointer;
  font-size: 22px;
  padding-left: 50px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.17);
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 15px 15px 15px 51px;
  height: 45px;
}

label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 16px;
  opacity: 0;
}

.styles_wrap_checkbox__3ixYW .styles_check_mark__7hvED {
  position: absolute;
  transform: translate(0%, -50%);
  left: 15px;
  top: 50%;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  border-radius: 4px;
}

.styles_wrap_checkbox__3ixYW .styles_label__tS-F- {
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #fff;
}

.styles_checked__1TH9P {
  background: white;
}

.styles_checked__1TH9P .styles_check_mark__7hvED {
  background-color: white;
  border: 1px solid black;
}

.styles_checked__1TH9P .styles_label__tS-F- {
  color: #000;
}

.styles_checked__1TH9P .styles_check_mark__7hvED::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.button_button__3Z9Vo {
  font-size: 14px;
  height: 35px;
  border-radius: 27px;
  font-weight: 700;
  width: 100px;
  position: relative;
  border: 1px solid white;
}

.button_button__3Z9Vo:focus {
  outline: none;
}

.button_button_submit__2Cc9n {
  background: white;
  color: black;
}

.button_button_back__8aFEB {
  background: transparent;
  color: white;
}

.button_button_back__8aFEB:only-child {
  margin: 0 auto;
}
.styles_step_first__3uLon .styles_title__Z-hFY,
.styles_step_second__37kGx .styles_title__Z-hFY,
.styles_success__387ux .styles_title__Z-hFY {
  font-size: 21px;
  margin-bottom: 12.5px;
}

.styles_step_first__3uLon .styles_button_submit__3Xhox {
  left: 36%;
}

@media screen and (max-width: 445px) {
  .styles_step_first__3uLon .styles_button_submit__3Xhox {
    left: 0;
  }
  .styles_step_first__3uLon .styles_absolute_err__WUqf3 {
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin-top: 0;
  }
}

.styles_step_second__37kGx form > div {
  margin: 20px 0;
}

.styles_step_second__37kGx form > div:first-child {
  margin-top: 25px;
}

.styles_step_second__37kGx form > div:last-child {
  margin-top: 25px;
}

.styles_step_second__37kGx form > div div {
  border: none;
}
.styles_step_second__37kGx form > div:last-child {
  margin-bottom: 0;
}

.styles_success__387ux .styles_wrap_success_info__1oo5m {
  margin: 51px 35px 47px 35px;
}

.styles_success__387ux .styles_wrap_success_info__1oo5m p:nth-child(2),
.styles_success__387ux .styles_wrap_success_info__1oo5m p:nth-child(3) {
  margin-top: 25px;
  margin-bottom: 25px;
}

.styles_input__xoNxn {
  background: #1a1a1a;
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 20px;
  color: #ffffff;
  width: 100%;
  outline: none;
  margin-bottom: 25px;
}
.styles_form_required__1BcKR {
  width: 340px;
  display: block;
  position: relative;
  margin-bottom: 0px;
  box-sizing: border-box;
}

@media screen and (max-width: 445px) {
  .styles_form_required__1BcKR {
    width: 270px;
  }
}

@media screen and (max-width: 320px) {
  .styles_form_required__1BcKR {
    width: 245px;
  }
}

.styles_form_required__1BcKR input {
  box-sizing: border-box;
}
.styles_border_input__2Pi14 {
  border: 1px solid rgba(255, 255, 255, 0.17);
}

.styles_form_required__1BcKR input[required] + label {
  pointer-events: none;
  margin: 12px 11px;
  padding: 0;
  white-space: nowrap;
  color: #999;
  font-size: 16px;
  left: 10px;
  color: #ffffff;
  position: absolute;
}

.styles_form_required__1BcKR input[required]:valid + label {
  display: none;
}

.styles_form_required__1BcKR input[required]:invalid + label {
  display: inline-block;
}

.styles_form_required__1BcKR input[required]:invalid + label:after {
  content: "*";
  color: #e4293a;
}

.styles_input_group__31zmN {
  display: flex;
  align-items: center;
  background: #1a1a1a;
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 20px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 25px;
}

.styles_input_group_in_input__3MtAz {
  border: none;
  margin: 0;
  padding: 0;
  border-radius: unset;
  margin-left: 15px;
}

.styles_input_icons__3crXn {
  font-size: 16px;
}

.styles_input__xoNxn::placeholder,
.styles_combobox_phone_number__TVA4I input::placeholder {
  color: #ffffff;
}

.styles_wrap_phone_number__3R-GF {
  margin-bottom: 25px;
  background: #1a1a1a;
  border-radius: 10px;
  position: relative;
}

.styles_wrap_phone_number__3R-GF label {
  position: absolute;
  right: 20px;
  top: 12px;
}

.styles_combobox_phone_number__TVA4I > div {
  display: flex;
  border-radius: 10px;
}

.styles_combobox_phone_number__TVA4I > div > div {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 20px;
}

.styles_combobox_phone_number__TVA4I select {
  background-color: transparent;
  border: none;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  outline: none;
  position: absolute;
  width: 100%;
  opacity: 0;
}

.styles_combobox_phone_number__TVA4I option {
  color: #000000;
}

.styles_combobox_phone_number__TVA4I input {
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  width: 100%;
  background: #1a1a1a;
  width: 100%;
}

.styles_combobox_phone_number__TVA4I > div > div > div:last-child {
  display: block;
  content: "";
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 10px;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;

  opacity: 0.7;
  font-size: 16px;
  transition: color 0.1s;
}

.styles_error_input__ki8wy {
  border: 1px solid #e4293a;
}

.styles_error_input_phone__nJDcu > div {
  border: 1px solid #e4293a;
}

.styles_error_form__BK6HG {
  color: white;
  font-size: 14px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
}

.styles_absolute_err__WUqf3 {
  position: absolute;
  bottom: -15px;
  text-align: left;
  width: 70%;
}

.styles_warning_icon__26Eef {
  margin-right: 16px;
}

.styles_require_phone_number__3eGc1 {
  color: #e4293a;
  width: 0;
  height: 0;
}

@media screen and (max-width: 445px) {
  .styles_require_phone_number__3eGc1 {
    margin-right: 55px !important;
  }
  .styles_input_group_in_input__3MtAz {
    margin-left: 5px;
  }
}

@media screen and (max-width: 320px) {
  .styles_require_phone_number__3eGc1 {
    margin-right: 31px !important;
  }
}

.PlacesAutocomplete_Input__2225f {
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px 20px;
  font-size: 15px;
  color: #939598;
  font-weight: 100;
  outline: none;
  width: 290px;
  font-size: 16px;
}

.PlacesAutocomplete_InputError__3FcsP {
  border: 1px solid #e04344;
}

.PlacesAutocomplete_ErrorInfo__8NqMB {
  color: #e04344;
}

.PlacesAutocomplete_menu__3XNv1 {
  z-index: 100;
  background: white;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border-radius: 18px;
}

.PlacesAutocomplete_item__16yve {
  margin: 10px;
  border-radius: 18px;
  display: block;
  color: black;
  padding: 2px 6px;
  cursor: pointer;
}

.PlacesAutocomplete_itemHighlighted__IFoTA {
  color: black;
}

.PlacesAutocomplete_AdminInput__FfJOF {
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid white;
  background: none;
  color: white;
  font-size: 19px;
  padding: 10px 15px;
  outline: none;

  position: relative;
  width: 100%;
  flex-shrink: 0;
  cursor: pointer;
}

.PlacesAutocomplete_AdminInput__FfJOF::placeholder {
  color: white;
  opacity: 1;
}

.Modal_modalOpenBody__jdItR {
  position: fixed;
  overflow: hidden;
  width: 100%;
}

.Modal_Modal__1DO2r {
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  flex-direction: column;
}

.Modal_ModalBody__2VYSl {
  height: 100%;
  width: 100%;
  position: relative;
  background: black;
  max-width: 750px;
  height: 100%;
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.22);

  padding: 40px 0;
}

.Modal_ModalHeader__2m65X {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  text-align: center;
  width: 100%;
  z-index: 1;
  flex-shrink: 0;

  margin-bottom: 20px;
}

.Modal_ModalContent__2BS_p {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.Modal_ModalCloseBtn__3tnCZ {
  position: absolute;
  top: 0;
  right: 0;

  height: auto;
  margin: 20px;
  width: 30px;
  cursor: pointer;
  z-index: 999;
}

.Modal_ModalLogo__h3tye {
  width: 180px;
  height: auto;
  margin-bottom: 20px;
}

.Modal_ModalBody__2VYSl .Modal_h2__28IXk {
  color: white;
  font-size: 20px;
  font-weight: 300;
  margin: 0 auto;
  max-width: 400px;
}

.Modal_BlackBackground__3_kSh {
  background-color: black;
  width: 100%;
}

.Footer_Footer__1xnq3 {
  color: rgb(179, 179, 179);
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
}

.Footer_FooterButton__3zVCL {
  border: none;
  background: none;
  outline: none;
  font-size: 12px;
  color: rgb(179, 179, 179);
  margin-top: 15px;
  cursor: pointer;
}

.Footer_ContactUs__3qdaF {
  font-weight: bold;
  text-decoration: underline;
}

.styles_wrap_checkbox__7MLuz {
  cursor: pointer;
  font-size: 22px;
  width: 0px;
  display: flex;
  align-items: center;
}

.styles_wrap_checkbox__7MLuz input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 16px;
}

.styles_wrap_checkbox__7MLuz .styles_check_mark__3ErMJ {
  position: absolute;
  transform: translate(0%, -50%);
  left: 20px;
  top: 24px;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  border-radius: 4px;
}

input:checked ~ .styles_check_mark__3ErMJ {
  background-color: white;
  border: 1px solid black;
}

input:checked ~ .styles_check_mark__3ErMJ::after {
  display: block;
}

.styles_wrap_checkbox__7MLuz .styles_check_mark__3ErMJ::after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

