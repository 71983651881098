.footer {
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  padding: 100px 0px 0px;
}

.logo {
  margin: 0 auto;
  max-width: 380px;
}

.title {
  font-size: 24px;
  line-height: 29px;
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 33px;
  margin-bottom: 19px;
}

.text_information {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  opacity: 0.53;
  margin: 22px;
}

.footer_bottom {
  background-color: #1d1d1f;
  padding: 15px 0px;
  margin-top: 30px;
}

.footer_bottom_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 466px;
  margin: 0 auto;
}

.footer_bottom_wrap_text {
  text-align: left;
  font-size: 10px;
  line-height: 19px;
  margin-right: 29px;
  width: 337px;
}

.footer_bottom_wrap_text a {
  text-decoration: underline;
  font-weight: 700;
}

.footer_bottom_wrap_button,
.button {
  background: #ffffff;
  border-radius: 27px;
  color: #1d1d1f;
  font-weight: bold;
  line-height: 16px;
  cursor: pointer;
  font-weight: 700;
}

.footer_bottom_wrap_button {
  width: 100px;
  font-size: 12px;
  text-align: center;
  padding: 7px 8px;
  height: 30px;
}

.contact_us_button_container {
  display: flex;
  justify-content: center;
}

.contact_us_button_container p {
  font-size: 12px;
  line-height: 19px;
  font-family: "Open Sans";
}

.need_help {
  font-size: 12px;
  line-height: 19px;
  padding: 100px 0px;
  font-family: "Open Sans";
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 767px) {
  .footer {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 440px) {
  .footer {
    padding-top: 36px;
  }
  .title {
    font-size: 20px;
  }
  .footer .form {
    margin-top: 0;
    width: calc(100% - 34px);
    margin: 0 auto;
  }
  .form form {
    width: 100%;
  }
  .form input {
    width: 100%;
    font-size: 13px;
  }
  .logo {
    max-width: 320px;
  }
  .text_information {
    font-size: 13px;
    max-width: 235px;
    margin: 22px auto;
  }
  .footer_bottom {
    display: none;
  }
  .logo {
    max-width: 290px;
  }
}
