.modal {
  backdrop-filter: blur(6px);
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.69);
  overflow-y: auto;
}

@media screen and (max-width: 550px) {
  .modal {
    padding: 0 15px;
  }
}

.modal .modal_content {
  position: relative;
  margin: 88px auto;
  max-width: 516px;
  width: 100%;
  height: auto;
  padding: 24px 75px;
  border-radius: 5px;
  border: 1px solid #979797;
  text-align: center;
  background-color: black;
  color: white;
  font-family: "Open Sans";
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@media screen and (max-width: 580px) {
  .modal .modal_content {
    padding: 24px;
  }
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.close_icon {
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  margin: 30px;
  width: 20px;
  cursor: pointer;
}

@media screen and (max-width: 460px) {
  .close_icon {
    margin: 15px;
    width: 15px;
  }
}

/* @media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
} */
