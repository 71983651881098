.sticky {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background: transparent;
  transition: all 0.5s;
}

.sticky.fixed {
  background: rgba(0, 0, 0, 0.9);
}

@media only screen and (max-width: 767px) {
  .sticky {
    bottom: 0;
    top: auto;
    background: rgba(0, 0, 0, 0.9);
  }
}
