.step_first .title,
.step_second .title,
.success .title {
  font-size: 21px;
  margin-bottom: 12.5px;
}

.step_first .button_submit {
  left: 36%;
}

@media screen and (max-width: 445px) {
  .step_first .button_submit {
    left: 0;
  }
  .step_first .absolute_err {
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin-top: 0;
  }
}

.step_second form > div {
  margin: 20px 0;
}

.step_second form > div:first-child {
  margin-top: 25px;
}

.step_second form > div:last-child {
  margin-top: 25px;
}

.step_second form > div div {
  border: none;
}
.step_second form > div:last-child {
  margin-bottom: 0;
}

.success .wrap_success_info {
  margin: 51px 35px 47px 35px;
}

.success .wrap_success_info p:nth-child(2),
.success .wrap_success_info p:nth-child(3) {
  margin-top: 25px;
  margin-bottom: 25px;
}

.input {
  background: #1a1a1a;
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 20px;
  color: #ffffff;
  width: 100%;
  outline: none;
  margin-bottom: 25px;
}
.form_required {
  width: 340px;
  display: block;
  position: relative;
  margin-bottom: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 445px) {
  .form_required {
    width: 270px;
  }
}

@media screen and (max-width: 320px) {
  .form_required {
    width: 245px;
  }
}

.form_required input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.border_input {
  border: 1px solid rgba(255, 255, 255, 0.17);
}

.form_required input[required] + label {
  pointer-events: none;
  margin: 12px 11px;
  padding: 0;
  white-space: nowrap;
  color: #999;
  font-size: 16px;
  left: 10px;
  color: #ffffff;
  position: absolute;
}

.form_required input[required]:valid + label {
  display: none;
}

.form_required input[required]:invalid + label {
  display: inline-block;
}

.form_required input[required]:invalid + label:after {
  content: "*";
  color: #e4293a;
}

.input_group {
  display: flex;
  align-items: center;
  background: #1a1a1a;
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 20px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 25px;
}

.input_group_in_input {
  border: none;
  margin: 0;
  padding: 0;
  border-radius: unset;
  margin-left: 15px;
}

.input_icons {
  font-size: 16px;
}

.input::placeholder,
.combobox_phone_number input::placeholder {
  color: #ffffff;
}

.wrap_phone_number {
  margin-bottom: 25px;
  background: #1a1a1a;
  border-radius: 10px;
  position: relative;
}

.wrap_phone_number label {
  position: absolute;
  right: 20px;
  top: 12px;
}

.combobox_phone_number > div {
  display: flex;
  border-radius: 10px;
}

.combobox_phone_number > div > div {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 20px;
}

.combobox_phone_number select {
  background-color: transparent;
  border: none;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  outline: none;
  position: absolute;
  width: 100%;
  opacity: 0;
}

.combobox_phone_number option {
  color: #000000;
}

.combobox_phone_number input {
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  width: 100%;
  background: #1a1a1a;
  width: 100%;
}

.combobox_phone_number > div > div > div:last-child {
  display: block;
  content: "";
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 10px;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;

  opacity: 0.7;
  font-size: 16px;
  transition: color 0.1s;
}

.error_input {
  border: 1px solid #e4293a;
}

.error_input_phone > div {
  border: 1px solid #e4293a;
}

.error_form {
  color: white;
  font-size: 14px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
}

.absolute_err {
  position: absolute;
  bottom: -15px;
  text-align: left;
  width: 70%;
}

.warning_icon {
  margin-right: 16px;
}

.require_phone_number {
  color: #e4293a;
  width: 0;
  height: 0;
}

@media screen and (max-width: 445px) {
  .require_phone_number {
    margin-right: 55px !important;
  }
  .input_group_in_input {
    margin-left: 5px;
  }
}

@media screen and (max-width: 320px) {
  .require_phone_number {
    margin-right: 31px !important;
  }
}
