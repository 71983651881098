.input_group {
  display: flex;
  align-items: center;
  background: #1a1a1a;
  padding: 0 20px;
  border-radius: 10px;
  margin: 12.5px 0;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.17);
}

.input_group input {
  margin: 0;
}

.input_group input {
  padding-left: 30px;
}

.row {
  position: relative;
}
