.wrap_checkbox {
  cursor: pointer;
  font-size: 22px;
  width: 0px;
  display: flex;
  align-items: center;
}

.wrap_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 16px;
}

.wrap_checkbox .check_mark {
  position: absolute;
  transform: translate(0%, -50%);
  left: 20px;
  top: 24px;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  border-radius: 4px;
}

input:checked ~ .check_mark {
  background-color: white;
  border: 1px solid black;
}

input:checked ~ .check_mark::after {
  display: block;
}

.wrap_checkbox .check_mark::after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
