.modalOpenBody {
  position: fixed;
  overflow: hidden;
  width: 100%;
}

.Modal {
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  flex-direction: column;
}

.ModalBody {
  height: 100%;
  width: 100%;
  position: relative;
  background: black;
  max-width: 750px;
  height: 100%;
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.22);

  padding: 40px 0;
}

.ModalHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  text-align: center;
  width: 100%;
  z-index: 1;
  flex-shrink: 0;

  margin-bottom: 20px;
}

.ModalContent {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.ModalCloseBtn {
  position: absolute;
  top: 0;
  right: 0;

  height: auto;
  margin: 20px;
  width: 30px;
  cursor: pointer;
  z-index: 999;
}

.ModalLogo {
  width: 180px;
  height: auto;
  margin-bottom: 20px;
}

.ModalBody .h2 {
  color: white;
  font-size: 20px;
  font-weight: 300;
  margin: 0 auto;
  max-width: 400px;
}

.BlackBackground {
  background-color: black;
  width: 100%;
}
